import React from 'react';
import { AuthProvider } from './AuthContext';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import EmploymentFormContainer from "./institute/EmploymentFormContainer";
import WelcomeMessage from "./WelcomeMessage";
import Overview from "./Overview";
import DetailCard from "./DetailCard";
import OverviewPage from "./OverviewPage";
import ProtectedRoute from "./ProtectedRoute";
import ContractContainerPage from "./staff/ContractContainerPage";
import EmployeeFormContainer from "./hr/EmployeeFormContainer";
import StaffMemberDashboard from "./staff/StaffMemberDashboard";
import Header from "./ui/Header";
import Footer from "./ui/Footer";
import Logout from "./Logout";
import HRDashboard from "./hr/HRDashboard";
import ContractsTable from "./staff/ContractsTable";
import InstituteDashboard from "./institute/InstituteDashboard";
import {PersistGate} from "redux-persist/integration/react";
import {Provider} from "react-redux";
import { store, persistor } from './redux/store';
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import SchoolContractsTable from "./school/SchoolContractsTable";
import FederalStateContractsTable from "./federalstate/FederalStateContractsTable";

function App() {
    return (
        <div className="App">
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}> {/* PersistGate hier verwenden */}
                    <AuthProvider>
                        <Router>
                            <Header/>
                            <div className="content">
                            <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false}
                                            newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable
                                            pauseOnHover/>
                            <Routes>
                                <Route exact path="/logout" element={<Logout/>}/>
                                <Route exact path="/" element={<WelcomeMessage/>}/>
                                <Route path="/mdb/" element={
                                    <ProtectedRoute allowedRoles={['staffmembers']}>
                                        <OverviewPage/>
                                    </ProtectedRoute>
                                }/>
                                <Route path="/mdb/staff/" element={
                                    <ProtectedRoute allowedRoles={['staffmembers']}>
                                        <StaffMemberDashboard/>
                                    </ProtectedRoute>
                                }/>
                                <Route path="/mdb/staff/contracts" element={
                                    <ProtectedRoute allowedRoles={['staffmembers']}>
                                        <ContractsTable/>
                                    </ProtectedRoute>
                                }/>
                                <Route path="/mdb/staff/contract" element={
                                    <ProtectedRoute allowedRoles={['staffmembers']}>
                                        <ContractContainerPage/>
                                    </ProtectedRoute>
                                }/>
                                <Route path="/mdb/hr" element={
                                    <ProtectedRoute allowedRoles={['humanressource']}>
                                        <HRDashboard/>
                                    </ProtectedRoute>
                                }/>
                                <Route path="/mdb/hr/overview" element={
                                    <ProtectedRoute allowedRoles={['humanressource']}>
                                        <Overview/>
                                    </ProtectedRoute>
                                }/>
                                <Route path="/mdb/hr/overview/detail" element={
                                    <ProtectedRoute allowedRoles={['humanressource']}>
                                        <DetailCard entries=""/>
                                    </ProtectedRoute>
                                }/>
                                <Route path="/mdb/institute/employment/new" element={
                                    <ProtectedRoute allowedRoles={['assistant','institute']}>
                                        <EmployeeFormContainer/>
                                    </ProtectedRoute>
                                }/>
                                <Route path="/mdb/institute/employment/details/:id" element={
                                    <ProtectedRoute allowedRoles={['assistant','institute']}>
                                        <EmploymentFormContainer/>
                                    </ProtectedRoute>
                                }/>
                                <Route path="/mdb/institute" element={
                                    <ProtectedRoute allowedRoles={['assistant','institute']}>
                                        <InstituteDashboard/>
                                    </ProtectedRoute>
                                }/>
                                <Route path="/mdb/institute/overview" element={
                                    <ProtectedRoute allowedRoles={['assistant','institute']}>
                                        <Overview/>
                                    </ProtectedRoute>
                                }/>
                                <Route path="/mdb/rectorate" element={
                                    <ProtectedRoute allowedRoles={['rectorate']}>
                                        <Overview/>
                                    </ProtectedRoute>
                                }/>
                                <Route path="/mdb/school" element={
                                    <ProtectedRoute allowedRoles={['school']}>
                                        <SchoolContractsTable/>
                                    </ProtectedRoute>
                                }/>
                                <Route path="/mdb/school" element={
                                    <ProtectedRoute allowedRoles={['federalstate']}>
                                        <FederalStateContractsTable/>
                                    </ProtectedRoute>
                                }/>
                                <Route path="/mdb/admin" element={
                                    <ProtectedRoute allowedRoles={['admin']}>
                                        <EmploymentFormContainer/>
                                    </ProtectedRoute>
                                }/>
                            </Routes>
                            </div>
                            <Footer/>
                        </Router>
                    </AuthProvider>
                </PersistGate>
            </Provider>
        </div>
    );
}

export default App;
