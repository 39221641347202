import {
    UPDATE_EMPLOYEE_SUCCESS,
    UPDATE_EMPLOYEE_FAILURE,
    UPDATE_CONTRACT,
    UPDATE_CONTRACT_SUCCESS,
    UPDATE_CONTRACT_FAILURE,
    UPDATE_EMPLOYMENTS,
    UPDATE_EMPLOYMENTS_SUCCESS,
    UPDATE_EMPLOYMENTS_FAILURE,
    UPDATE_SCHOOLYEAR,
    UPDATE_SCHOOLYEAR_SUCCESS,
    UPDATE_SCHOOLYEAR_FAILURE,
    GET_SCHOOLYEARS_SUCCESS,
    GET_EMPLOYEE_SUCCESS,
    SET_EMPLOYEE,
    GET_INSTITUTES_SUCCESS,
    GET_INSTITUTES_FAILURE,
    ADD_EMPLOYMENT_SUCCESS,
    REMOVE_EMPLOYMENT_SUCCESS,
    ADD_EMPLOYMENT_FAILURE,
    REMOVE_EMPLOYMENT_FAILURE,
    GET_EMPLOYMENTS_SUCCESS,
    GET_EMPLOYMENTS_FAILURE,
    GET_JOBPROFILE_SUCCESS,
    UPDATE_JOBPROFILE_SUCCESS,
    UPDATE_JOBPROFILE_FAILURE,
    GET_FEDERALSTATES_SUCCESS,
    GET_FEDERALSTATES_FAILURE,
    GET_SCHOOLYEARS_FAILURE,
    GET_CLASSIFICATIONTYPES_SUCCESS,
    GET_USAGETYPES_SUCCESS,
    SHOW_TOAST,
    GET_CONTRACTS_SUCCESS,
    GET_CONTRACTS_FAILURE,
    UPDATE_EMPLOYMENTUNITS_SUCCESS,
    UPDATE_EMPLOYMENTUNITS_FAILURE,
    CONFIRM_EMPLOYMENT_SUCCESS,
    CONFIRM_CONTRACT_SUCCESS,
    RESET_STORE,
    GET_CONTRACT_SUCCESS,
    CREATE_EMPLOYEE_SUCCESS,
    CONFIRM_CONTRACT_FAILURE,
    CONFIRM_EMPLOYMENT_FAILURE,
    ADD_EMPLOYEE_FILES_SUCCESS, REMOVE_EMPLOYEE_FILE_SUCCESS, DELETE_CONTRACT_SUCCESS, DELETE_CONTRACT_FAILURE
} from '../actions/actions';

const initialState = {
    employee: {},
    jobProfile: {},
    contract: {},
    employments: [],
    schoolYears: [],
    classificationTypes: [],
    federalStates: [],
    usageTypes: [],
    contracts: [],
    institutes: [],
    loading: false,
    toast: {},
    error: null
};

function removeEmployment(state, action) {
    const updatedEmployments = state.employments.filter(employment => {
        return employment.ID !== action.payload.ID;
    });

    return {
        ...state,
        employments: updatedEmployments,
        loading: false,
        error: null
    };
}


const jointPowerReducer = (state = initialState, action) => {
    switch (action.type) {
        case RESET_STORE:
            return initialState;
        case GET_EMPLOYEE_SUCCESS:
            return { ...state, employee: action.payload, loading: false, error: null };
        case GET_SCHOOLYEARS_SUCCESS:
            return { ...state, schoolYears: action.payload, loading: false, error: null };
        case GET_CONTRACT_SUCCESS:
            return { ...state, contract: action.payload, loading: false, error: null };
        case SET_EMPLOYEE:
            return { ...state, employee: action.payload, loading: false, error: null };
        case ADD_EMPLOYMENT_SUCCESS:
            return { ...state, employments: [...state.employments, action.payload], loading: false, error: null };
        case REMOVE_EMPLOYMENT_SUCCESS:
            return { ...state, employments: state.employments.filter(employment => employment.ID !== parseInt(action.payload.ID, 10)), loading: false, error: null };
        case GET_INSTITUTES_SUCCESS:
            return { ...state, institutes: action.payload, loading: false, error: null };
        case GET_EMPLOYMENTS_SUCCESS:
            return { ...state, employments: action.payload, loading: false, error: null };
        case GET_JOBPROFILE_SUCCESS:
            return { ...state, jobProfile: action.payload, loading: false, error: null };
        case GET_FEDERALSTATES_SUCCESS:
            return { ...state, federalStates: action.payload, loading: false, error: null };
        case GET_CLASSIFICATIONTYPES_SUCCESS:
            return { ...state, classificationTypes: action.payload, loading: false, error: null };
        case GET_USAGETYPES_SUCCESS:
            return { ...state, usageTypes: action.payload, loading: false, error: null };
        case GET_CONTRACTS_SUCCESS:
            return { ...state, contracts: action.payload, loading: false, error: null };
        case DELETE_CONTRACT_SUCCESS:
            return { ...state, contracts: state.contracts.filter(contract => contract.contract.ID !== action.payload.ID), loading: false, error: null };
        case UPDATE_CONTRACT:
        case UPDATE_EMPLOYMENTS:
        case UPDATE_SCHOOLYEAR:
            return { ...state, loading: true, error: null };
        case CREATE_EMPLOYEE_SUCCESS:
            const { employee1, contract1, schoolYears1, ...rest1 } = action.payload;
            return {
                ...state,
                employee: employee1 || state.employee, // Setzt den neuen Wert für 'employee', oder behält den alten bei, falls 'employee' nicht im Payload ist
                contract: contract1 || state.contract, // Entsprechend für 'contract'
                schoolYears: schoolYears1 || state.schoolYears, // Entsprechend für 'schoolYears'
                ...rest1, // Andere Daten aus dem Payload, die evtl. in den State eingefügt werden sollen
                loading: false,
                error: null
            };
        case UPDATE_EMPLOYEE_SUCCESS:
            const { employee, contract, schoolYears, employments, ...rest } = action.payload;
            return {
                ...state,
                employee: employee || state.employee, // Setzt den neuen Wert für 'employee', oder behält den alten bei, falls 'employee' nicht im Payload ist
                contract: contract || state.contract, // Entsprechend für 'contract'
                schoolYears: schoolYears || state.schoolYears, // Entsprechend für 'schoolYears'
                employments: employments || state.employments, // Entsprechend für 'employments'
                ...rest, // Andere Daten aus dem Payload, die evtl. in den State eingefügt werden sollen
                loading: false,
                error: null
            };
        case UPDATE_JOBPROFILE_SUCCESS:
            return { ...state, jobProfile: action.payload, loading: false };
        case UPDATE_CONTRACT_SUCCESS:
            return { ...state, contract: action.payload, loading: false };
        case UPDATE_EMPLOYMENTS_SUCCESS:
            return { ...state, employments: action.payload, loading: false };
        case UPDATE_EMPLOYMENTUNITS_SUCCESS:
            return {
                ...state,
                contracts: state.contracts.map(contract => {
                    if (contract.contract.ID === action.payload.ContractID) {
                        const updatedEmployments = contract.employments.map(employmentEntry => {
                            if (employmentEntry.employment.ID === action.payload.ID) {
                                return {
                                    ...employmentEntry,
                                    employment: {
                                        ...employmentEntry.employment,
                                        NumOfUnits: action.payload.NumOfUnits,
                                        NumOfUnitsTeaching: action.payload.NumOfUnitsTeaching
                                    } };
                            }
                            return employmentEntry;
                        });
                        return { ...contract, employments: updatedEmployments };
                    }
                    return contract;
                })
            };
        case CONFIRM_EMPLOYMENT_SUCCESS:
            return {
                ...state,
                contracts: state.contracts.map(contractEntry => {
                    // Aktualisieren des betroffenen Contracts
                    if (contractEntry.contract.ID === action.payload.contract.ID) {
                        // Überprüfen, ob der Status des Contract 'INSTITUTE' ist
                        return {
                            ...contractEntry,
                            contract: {
                                ...contractEntry.contract,
                                Status: action.payload.contract.Status
                            },
                            employments: contractEntry.employments.map(employmentEntry => {
                                if (employmentEntry.employment.ID === action.payload.employment.ID) {
                                    return {
                                        ...employmentEntry,
                                        employment: {...employmentEntry.employment, ...action.payload.employment}
                                    };
                                }
                                return employmentEntry;
                            })
                        };
                    }
                    return contractEntry;
                })
            };
        case CONFIRM_CONTRACT_SUCCESS:
            return {
                ...state,
                contracts: state.contracts.map(contractEntry => {
                    if (contractEntry.contract.ID === action.payload.contract.ID) {
                        return {
                            ...contractEntry,
                            contract: {
                                ...contractEntry.contract,
                                ...action.payload.contract
                            }
                        };
                    }
                    return contractEntry;
                })
            };
        case UPDATE_SCHOOLYEAR_SUCCESS:
            return { ...state, schoolYear: action.payload, loading: false };
        case ADD_EMPLOYEE_FILES_SUCCESS:
            const existingFiles = state.employee.EmployeeFiles || [];
            const newFiles = action.payload.EmployeeFiles || [];
            return {
                ...state,
                employee: {
                    ...state.employee,
                    EmployeeFiles: [...existingFiles, ...newFiles]
                }
            };
        case REMOVE_EMPLOYEE_FILE_SUCCESS:
            return {
                ...state,
                employee: {
                    ...state.employee,
                    EmployeeFiles: state.employee.EmployeeFiles.filter(file => file.ID !== action.payload.ID)
                }
            };
        case UPDATE_EMPLOYEE_FAILURE:
        case UPDATE_CONTRACT_FAILURE:
        case UPDATE_EMPLOYMENTS_FAILURE:
        case UPDATE_EMPLOYMENTUNITS_FAILURE:
        case UPDATE_JOBPROFILE_FAILURE:
        case GET_INSTITUTES_FAILURE:
        case GET_EMPLOYMENTS_FAILURE:
        case GET_FEDERALSTATES_FAILURE:
        case GET_SCHOOLYEARS_FAILURE:
        case GET_CONTRACTS_FAILURE:
        case DELETE_CONTRACT_FAILURE:
        case ADD_EMPLOYMENT_FAILURE:
        case REMOVE_EMPLOYMENT_FAILURE:
        case CONFIRM_CONTRACT_FAILURE:
        case CONFIRM_EMPLOYMENT_FAILURE:
        case UPDATE_SCHOOLYEAR_FAILURE:
            if (action.payload === 'TOKEN_EXPIRED') {
                //TODO logout
            }
            return { ...state, loading: false, error: action.payload };
        case SHOW_TOAST:
            return { ...state, toast: action.payload };
        default:
            return state;
    }
};

export default jointPowerReducer;
