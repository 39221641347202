import React, { useState } from "react";
import LoginForm from "./LoginForm";
import {useAuth} from "./AuthContext";
import {useNavigate} from "react-router-dom";

function WelcomeMessage() {
    const [showLoginForm, setShowLoginForm] = useState(false);
    const { handleMicrosoftLogin } = useAuth();
    const navigate = useNavigate();
    const handleLoginClick = () => {
        setShowLoginForm(true);
    };

    const handleLoginMicrosoftClick = () => {
        handleMicrosoftLogin((userGroups) => {
            if (userGroups.includes('staffmembers')) {
                navigate('/mdb/staff');
            } else if (userGroups.includes('humanressource')) {
                navigate('/mdb/hr');
            } else if (userGroups.includes('institute')) {
                navigate('/mdb/institute');
            } else if (userGroups.includes('rectorate')) {
                navigate('/mdb/rectorate');
            } else if (userGroups.includes('admin')) {
                navigate('/mdb/admin');
            }
        });
    };

    return (
        <div className="container my-5">
            <div className="row justify-content-center">
                <div className="col-md-6 text-center">
                    {/* Logo Platzhalter */}
                    <img src="logo.jpg" alt="Logo" className="mb-4" style={{ maxWidth: '150px' }} />

                    <h1 className="mb-3">Willkommen zur Mitverwendungsdatenbank</h1>
                    <p>Hier finden Sie alle Informationen zu unseren Mitverwendungen.</p>

                    {/* Login Button */}
                    {!showLoginForm && (
                        <div className="mt-4">
                            <button onClick={handleLoginClick} className="btn btn-primary">
                                Login
                            </button>
                        </div>
                    )}

                    {/* Login Form */}
                    {showLoginForm && (
                        <LoginForm/>
                    )}
                </div>
            </div>
        </div>
    );
}

export default WelcomeMessage;
