import React from 'react';
import { Link } from 'react-router-dom';
import {useSelector} from "react-redux";
import {FaHome} from "react-icons/fa";

function Navigation() {
    const userGroups = useSelector(state => state.user.userGroups);
    
    return (
        <span className="nav-link">
            { !userGroups.length && <Link to="/"><FaHome /></Link> }
            { userGroups.includes('staffmembers') && <Link to="/mdb/staff"><FaHome /></Link> }
            { userGroups.includes('humanressource') && <Link to="/mdb/hr"><FaHome /></Link> }
            { userGroups.includes('institute') && <Link to="/mdb/institute"><FaHome /></Link> }
            { userGroups.includes('rectorate') && <Link to="/mdb/rectorate"><FaHome /></Link> }
            { userGroups.includes('admin') && <Link to="/mdb/admin"><FaHome /></Link> }
        </span>
    );
}

export default Navigation;