import React, {useEffect} from 'react';
import {FaArrowLeft} from "react-icons/fa";
import '../error.css'
import {useDispatch, useSelector} from "react-redux";
import {useForm, Controller} from "react-hook-form";
import {getJobProfile, updateJobProfile} from "../redux/actions/actions";

const ProfileForm = ({ setCurrentStep, previousStep, submitButtonText }) => {
    const dispatch = useDispatch();
    const employee = useSelector(state => state.jointPower.employee); // Redux State für den aktuellen Mitarbeiter
    const jobProfile = useSelector(state => state.jointPower.jobProfile);
    const { register, handleSubmit,control, getValues, reset, formState: { errors } } = useForm({
        defaultValues: { tasks: jobProfile.tasks }
    });

    useEffect(() => {
        reset(jobProfile);
    }, [jobProfile, reset]);

    useEffect(() => {
        if (employee.ID && !jobProfile.ID) {
            dispatch(getJobProfile(employee.ID));
        }
    }, [dispatch, jobProfile, employee.ID]);

    const onSubmit = () => {
        const data = getValues();
        const updatedTasks = jobProfile.tasks.map((task, index) => ({
            ...task,
            IsActive: data.tasks[index].IsActive ? 1 : 0,
        }));
        const updatedJobProfile = {
            ...jobProfile,
            tasks: updatedTasks,
            AdditionalInfo: data.AdditionalInfo, // Speichert zusätzliche Erläuterungen
            ResultDefinition: data.ResultDefinition, // Speichert Zieldefinition
        };
        dispatch(updateJobProfile(updatedJobProfile)); // Dispatch der Redux-Aktion mit den aktualisierten Daten
        setCurrentStep();
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="form-container">
            <div className="mb-3 form-field">
                <label className="form-label" style={{marginBottom: "3.5vh"}}>Aufgabenprofil</label>
                {jobProfile.tasks && jobProfile.tasks.map((task, index) => (
                    <div key={index} className="form-check">
                        <Controller
                            name={`tasks[${index}].IsActive`}
                            control={control}
                            defaultValue={task.IsActive === 1}
                            render={({field}) => (
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    {...field}
                                    checked={field.value}
                                />
                            )}
                        />
                        <label className="form-check-label" htmlFor={`tasks[${index}]`}>
                            {task.Description}
                        </label>
                    </div>
                ))}
            </div>
            <div className="mb-3 form-field">
                <div className="mb-3 form-group">
                    <label htmlFor="AdditionalInfo" className="form-label">Zusätzliche Erläuterungen:</label>
                    <textarea
                        {...register("AdditionalInfo")}
                        id="AdditionalInfo"
                        defaultValue={jobProfile.AdditionalInfo}
                        className="form-control"
                        rows="10" // Stellt die Höhe des Textfelds ein
                        style={{width: '100%', overflow: 'auto'}} // Stellt die Breite des Textfelds ein
                    />
                </div>
            </div>
            <div className="mb-3 form-field">
                <div className="mb-3 form-group">
                    <label htmlFor="ResultDefinition" className="form-label">Zieldefinition:</label>
                    <textarea
                        {...register("ResultDefinition")}
                        id="ResultDefinition"
                        defaultValue={jobProfile.ResultDefinition}
                        className="form-control"
                        rows="10"
                        style={{width: '100%', overflow: 'auto'}}
                    />
                </div>
            </div>
            <div className="form-navigation-buttons">
                <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={previousStep}
                >
                    <FaArrowLeft/> Zurück
                </button>
                <button type="submit" className="btn btn-primary">{submitButtonText}</button>
            </div>
        </form>
    );
};

export default ProfileForm;
