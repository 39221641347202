import React, { useState } from 'react';
import { useAuth } from './AuthContext';
import { useNavigate } from 'react-router-dom';
import ResetPasswordForm from "./ResetPasswordForm";

const LoginForm = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showResetForm, setResetForm] = useState(false);
    const navigate = useNavigate();
    const { handleLogin } = useAuth();

    const handleSubmit = async (event) => {
        event.preventDefault();
        await handleLogin({ email, password }, (userGroups) => {
            if (userGroups.includes('staffmembers')) {
                navigate('/mdb/staff');
            } else if (userGroups.includes('humanressource')) {
                navigate('/mdb/hr');
            } else if (
                userGroups.includes('institute') ||
                userGroups.includes('assistant')
            ) {
                navigate('/mdb/institute');
            } else if (userGroups.includes('rectorate')) {
                navigate('/mdb/rectorate');
            } else if (userGroups.includes('school')) {
                navigate('/mdb/school');
            } else if (userGroups.includes('admin')) {
                navigate('/mdb/admin');
            }
        });
    };

    return (
        <div>
        { !showResetForm && (
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <input
                        type="email"
                        placeholder="Email"
                        className="form-control"
                        id="email"
                        value={email} // Stellen Sie sicher, dass value auf den Zustand verweist
                        onChange={(e) => setEmail(e.target.value)} // Aktualisieren Sie den Zustand bei Änderung
                    />
                </div>
                <div className="form-group py-2">
                    <input
                        type="password"
                        placeholder="Passwort"
                        className="form-control"
                        id="password"
                        value={password} // Stellen Sie sicher, dass value auf den Zustand verweist
                        onChange={(e) => setPassword(e.target.value)} // Aktualisieren Sie den Zustand bei Änderung
                    />
                </div>
                <div className="mb-2 py-2 text-end">
                    <button type="button" className="btn btn-link" onClick={() => setResetForm(true)}>Passwort vergessen?</button>
                    <button type="submit" className="btn btn-primary">Einloggen</button>
                </div>
            </form>
        )}
        { showResetForm && (
            <ResetPasswordForm setResetForm={setResetForm} />
        )}
        </div>

    );
};

export default LoginForm;
