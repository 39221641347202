import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useAuth } from "./AuthContext";
import { FaHome, FaUserPlus, FaUsers, FaFileAlt, FaUniversity, FaCogs } from 'react-icons/fa';

function OverviewPage() {
    const { userGroups } = useAuth();

    // Function to render links with icons based on user groups
    const renderLink = (group, path, description, IconComponent) => {
        return userGroups.includes(group) && (
            <Link to={path} className="dashboard-link">
                <IconComponent className="icon" />
                {description}
            </Link>
        );
    };

    return (
        <div className="container my-5">
            <h2 className="text-center mb-4 font-weight-bold">Mitverwendungsdatenbank - Übersicht</h2>
            <div className="list-group">
                {renderLink('staffmembers', '/mdb', 'Startseite', FaHome)}
                {renderLink('staffmembers', '/mdb/staff/new', 'Neue Anstellung', FaUserPlus)}
                {renderLink('humanressource', '/mdb/hr', 'Personalabteilung - Übersicht', FaUsers)}
                {renderLink('humanressource', '/mdb/hr/detail', 'Personalabteilung - Detailansicht', FaFileAlt)}
                {renderLink('rectorate', '/mdb/rectorate', 'Rektorat', FaUniversity)}
                {renderLink('admin', '/mdb/admin', 'Administration', FaCogs)}
            </div>
        </div>
    );
}

export default OverviewPage;
