import React from 'react';
import { Link } from 'react-router-dom';
import { FaUserFriends, FaUserPlus } from 'react-icons/fa';
import './HRDashboard.css';

function HRDashboard() {
    return (
        <div className="dashboard-container">
            <h2 className="dashboard-title">HR-Dashboard</h2>
            <div className="dashboard-links">
                <Link to="/mdb/hr/overview" className="dashboard-link">
                    <FaUserFriends className="icon" />
                    Übersicht über Mitverwendungen
                </Link>
                {/* Weitere Links und Icons können hier hinzugefügt werden */}
            </div>
        </div>
    );
}

export default HRDashboard;
