import { combineReducers } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';

import {persistReducer} from "redux-persist";
import jointPowerReducer from "./jointPowerReducer";
import userReducer from "./userReducer";

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['user']
}

// Kombinieren Sie Reducer
const rootReducer = combineReducers({
    jointPower: jointPowerReducer,
    user: userReducer,
});

// Persistierter Reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

export default persistedReducer;