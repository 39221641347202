import { Modal, Button } from 'react-bootstrap';

function ConfirmDeleteModal({ show, onHide, onConfirm }) {
    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Vertrag löschen</Modal.Title>
            </Modal.Header>
            <Modal.Body>Sind Sie sicher, dass Sie diesen Vertrag löschen möchten?</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Abbrechen
                </Button>
                <Button variant="primary" onClick={onConfirm}>
                    Löschen
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ConfirmDeleteModal;