import React, {useCallback, useEffect} from 'react';
import './PersonalDataForm.css'
import '../error.css'
import {useDispatch, useSelector} from "react-redux";
import {getEmployee, updateEmployee} from "../redux/actions/actions";
import {useForm} from "react-hook-form";
import {decodeJWT} from "../jwtUtils";
import { parsePhoneNumberFromString } from 'libphonenumber-js';

const PersonalDataForm = ({contract, setCurrentStep}) => {
    const dispatch = useDispatch();
    const employee = useSelector(state => state.jointPower.employee); // Redux State für den aktuellen Mitarbeiter
    const { register, handleSubmit, reset, watch, setValue, formState: { errors } } = useForm({
        defaultValues: employee // Setzt die Standardwerte des Formulars auf die aktuellen Mitarbeiterdaten
    });

    useEffect(() => {
        if (!employee.ID) {
            dispatch(getEmployee({contractID: contract.ID}));
        }
    }, [dispatch, employee, contract.ID]);

    useEffect(() => {
        reset(employee);
    }, [employee, reset]);


    const onSubmit = data => {
        dispatch(updateEmployee(data)); // Dispatch der Redux-Aktion mit den Formulardaten
        setCurrentStep();
    };

    const singleStatus = watch("SingleStatus");

    const country = watch("Country");

    // Validierungsfunktion für Postleitzahl
    const validatePostalCode = useCallback((postalCode) => {
        let regex;
        switch (country) {
            case 'Deutschland': // Deutschland
                regex = /^\d{5}$/;
                break;
            case 'Österreich': // Österreich
                regex = /^\d{4}$/;
                break;
            case 'Slowakei': // Slowakei
                regex = /^\d{3}\s?\d{2}$/;
                break;
            case 'Ungarn': // Slowakei
                regex = /^[1-9]\d{3}$/;
                break;
            default:
                return true; // Keine Validierung, wenn kein Land ausgewählt ist
        }
        return regex.test(postalCode);
    }, [country]);


    const validatePhoneNumber = (phoneNumber) => {
        const parsedNumber = parsePhoneNumberFromString(phoneNumber);
        const parsedNumberAT = parsePhoneNumberFromString(phoneNumber, "AT");
        if (parsedNumber && parsedNumber.isValid()) {
            return true;
        }
        return parsedNumberAT ? parsedNumberAT.isValid() : false;

    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="form-container">
            <div className="mb-3 form-field">
                <label className="form-label">Titel</label>
                <input {...register("Title")} type="text" className="form-control"/>
            </div>
            <div className="mb-3 form-field">
                <label htmlFor="GivenName" className="form-label">Vorname</label>
                <input {...register("GivenName", {required: "Vorname ist erforderlich!"})} type="text"
                       className="form-control"/>
                {errors.GivenName && <p className="error-message">{errors.GivenName.message}</p>}
            </div>
            <div className="mb-3 form-field">
                <label htmlFor="FamilyName" className="form-label">Nachname</label>
                <input {...register("FamilyName", {required: "Nachname ist erforderlich!"})}
                       type="text" className="form-control"/>
                {errors.FamilyName && <p className="error-message">{errors.FamilyName.message}</p>}
            </div>
            <div className="mb-3 form-field">
                <label className="form-label">Titel danach</label>
                <input {...register("TitleAfter")} type="text" className="form-control"/>
            </div>
            <div className="mb-3 form-field">
                <label htmlFor="Birthday" className="form-label">Geburtstag</label>
                <input {...register("Birthday", {required: "Geburtstag ist erforderlich!"})}
                       type="date" className="form-control"/>
                {errors.Birthday && <p className="error-message">{errors.Birthday.message}</p>}
            </div>
            <div className="mb-3 form-field">
                <label htmlFor="SocialSecurityNumber" className="form-label">Sozialversicherungsnummer</label>
                <input {...register("SocialSecurityNumber", {
                    required: "Sozialversicherungsnummer ist erforderlich!"
                })}
                       type="number" className="form-control"/>
                {errors.SocialSecurityNumber && <p className="error-message">{errors.SocialSecurityNumber.message}</p>}
            </div>
            <div className="mb-3 form-field">
                <label htmlFor="PostalCode" className="form-label">Postleitzahl</label>
                <input {...register("PostalCode", {
                    required: "Postleitzahl ist erforderlich!",
                    validate: validatePostalCode
                })}
                       type="text" className="form-control"/>
                {errors.PostalCode && <p className="error-message">{errors.PostalCode.message || "Ungültige Postleitzahl"}</p>}
            </div>
            <div className="mb-3 form-field">
                <label htmlFor="City" className="form-label">Ort</label>
                <input {...register("City", {required: "Ort ist erforderlich!"})}
                       type="text" className="form-control"/>
                {errors.City && <p className="error-message">{errors.City.message}</p>}
            </div>
            <div className="mb-3 form-field">
                <label htmlFor="Country" className="form-label">Land</label>
                <select {...register("Country", {required: "Land ist erforderlich!"})} className="form-select">
                    <option value="Österreich">Österreich</option>
                    <option value="Deutschland">Deutschland</option>
                    <option value="Ungarn">Ungarn</option>
                    <option value="Slowakei">Slowakei</option>
                </select>
                {errors.Country && <p className="error-message">{errors.Country.message}</p>}
            </div>
            <div className="mb-3 form-field">
                <label htmlFor="Street" className="form-label">Straße / Hausnummer</label>
                <input {...register("Street", {required: "Straße ist erforderlich!"})}
                       type="text" className="form-control"/>
                {errors.Street && <p className="error-message">{errors.Street.message}</p>}
            </div>
            <div className="mb-3 form-field">
                <label htmlFor="PhoneNumber" className="form-label">Telefonnummer</label>
                <input
                    {...register("PhoneNumber", {
                        required: "Telefonnummer ist erforderlich!",
                        validate: validatePhoneNumber
                    })}
                    type="text" className="form-control"/>
                {errors.PhoneNumber && <p className="error-message">{errors.PhoneNumber.message || "Ungültige Telefonnummer"}</p>}
            </div>
            <div className="mb-3 form-field">
                <label htmlFor="Nationality" className="form-label">Staatsangehörigkeit</label>
                <input
                    {...register("Nationality", {required: "Staatsangehörigkeit ist erforderlich!"})}
                    type="text" className="form-control"/>
                {errors.Nationality && <p className="error-message">{errors.Nationality.message}</p>}
            </div>
            <div className="mb-3 form-field">
                <label className="form-label">Gender</label>
                <select {...register("Gender")} className="form-select">
                    <option value="NONE">Wählen Sie ein Geschlecht</option>
                    <option value="MALE">Männlich</option>
                    <option value="FEMALE">Weiblich</option>
                    <option value="OTHER">Divers</option>
                </select>
            </div>
            <hr/>
            <div className="mb-3 form-field">
                <label className="form-label">Personalnummer Stammdienststelle</label>
                <input
                    {...register("SchoolEmployeeNumber", {required: "Personalnummer ist erforderlich!"})}
                    type="text" className="form-control"/>
                {errors.SchoolEmployeeNumber &&
                    <p className="error-message">{errors.SchoolEmployeeNumber.message}</p>}
            </div>
            <div className="mb-3 form-field">
                <label htmlFor="TitleOfficial" className="form-label">Amtstitel</label>
                <input
                    {...register("TitleOfficial")}
                    type="text" className="form-control"/>
            </div>
            <div className="mb-3 form-field">
                <label className="form-label">Dienstverhältnis</label>
                <select {...register("StateOrFederal", {required: "Lehrertyp ist auszuwählen!"})}
                        className="form-select">
                    <option value="NONE" disabled>Wählen Sie...</option>
                    <option value="FEDERAL">Dienstverhältnis Land</option>
                    <option value="STATE">Dienstverhältnis Bund</option>
                    <option value="PD">Pädagogischer Dienst</option>
                </select>
            </div>
            <div className="form-navigation-buttons">
                <button type="submit" className="btn btn-primary">Next</button>
            </div>
        </form>
    );
};

export default PersonalDataForm;
