import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';
import {useSelector} from "react-redux";
import Navigation from "./Navigation";

const Footer = () => {
    const authToken = useSelector(state => state.user.authToken)

    return (
        <footer className="bg-light text-center text-lg-start footer" style={{ marginTop: "100px" }}>
            <div className="container p-4">
                <div className="row">
                    <div className="col-6 col-md-6 mb-4 mb-md-0 d-none d-md-block">
                        <h5 className="text-uppercase">Mitverwendung</h5>
                    </div>
                    <div className="col-6 col-md-6 mb-4 mb-md-0 d-none d-md-block text-end">
                        <h5 className="text-uppercase">Links</h5>
                        <ul className="list-unstyled mb-0">
                            { authToken && (
                                <li>
                                    <Link to="/logout" className="text-dark">Logout</Link>
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
            </div>

            <div className="text-center p-3" style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
                © {new Date().getFullYear()} Mitverwendungsdatenbank
            </div>
        </footer>
    );
};

export default Footer;
