import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';

const Logout = () => {
    const navigate = useNavigate();
    const { logout } = useAuth();

    useEffect(() => {
        logout();
        navigate('/');
    }, [logout, navigate]);

    return null; // oder Sie können eine Art von "Sie werden ausgeloggt..." Nachricht anzeigen
};

export default Logout;
