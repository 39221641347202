import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {createEmployee, getFederalStates, getSchoolYears, setEmployee} from "../redux/actions/actions";
import {useNavigate} from "react-router-dom";

const EmployeeForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const schoolYears = useSelector(state => state.jointPower.schoolYears);
    const employee = useSelector(state => state.jointPower.employee) || {};
    const federalStates = useSelector(state => state.jointPower.federalStates);

    useEffect(() => {
        if (!schoolYears.length) {
            dispatch(getSchoolYears());
        }
    }, [dispatch, schoolYears]);

    useEffect(() => {
        if (!federalStates.length) {
            dispatch(getFederalStates());
        }
    }, [dispatch, federalStates]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        dispatch(createEmployee(employee));
        navigate("/mdb/institute/overview");
    }

    const handleChange = (e) => {
        const updatedEmployee = {...employee};
        updatedEmployee[e.target.name] = e.target.value;
        dispatch(setEmployee(updatedEmployee));
    }

    return (
        <div className="form-container text-start">
            <form onSubmit={handleSubmit} className="employee-form">
                <div className="mb-3 form-group">
                    <label htmlFor="email" className="form-label">E-Mail-Adresse des Mitverwendeten</label>
                    <input
                        type="email"
                        className="form-control"
                        id="Email"
                        name="Email"
                        value={employee.Email}
                        onChange={handleChange}
                    />
                </div>
                <div className="mb-3 form-group">
                    <label htmlFor="schoolYear" className="form-label">Schuljahr</label>
                    <select
                        className="form-select"
                        id="SchoolYearID"
                        name="SchoolYearID"
                        value={employee.SchoolYearID}
                        onChange={handleChange}
                    >
                        <option value="">Bitte wählen</option>
                        {schoolYears.map((schoolYear, i) => (
                            <option key={i} value={schoolYear.ID}>{schoolYear.Name}</option>
                        ))}
                    </select>
                </div>
                <div className="mb-3 form-group">
                    <label htmlFor="federalState" className="form-label">Bundesland der Stammschule</label>
                    <select
                        className="form-select"
                        id="FederalStateID"
                        name="FederalStateID"
                        value={employee.FederalStateID}
                        onChange={handleChange}
                    >
                        <option value="">Bitte wählen</option>
                        {federalStates.map((federalState, i) => (
                            <option key={i} value={federalState.ID}>{federalState.Name}</option>
                        ))}
                    </select>
                </div>
                <div className="form-navigation-buttons">
                    <button type="submit" className="btn btn-primary">Submit</button>
                </div>
            </form>
        </div>
    );
};

export default EmployeeForm;
