import React, {useEffect, useState} from 'react';
import FormSummary from "../FormSummary";
import {useDispatch, useSelector} from "react-redux";
import {getContract} from "../redux/actions/actions";
import PersonalDataForm from "./PersonalDataForm";
import AdditionalDataForm from "./AdditionalDataForm"; // Adjust the path to the DetailCard component

const ContractContainerPage =  () => {
    const dispatch = useDispatch();
    const [currentStep, setCurrentStep] = useState(1);
    const contract = useSelector(state => state.jointPower.contract);

    useEffect(() => {
        if (!contract.ID) {
            dispatch(getContract(0));
        }
    }, [dispatch, contract]);

    if (!contract.ID) {
        return <div>Loading ...</div>
    }

    const goToPreviousStep = () => setCurrentStep(currentStep - 1);
    const goToNextStep = () => setCurrentStep(currentStep + 1);

    const renderStep = () => {
        let submitButtonText = "Weiter";
        if (currentStep === 3) {
            submitButtonText = "Speichern";
        }
        if (contract.Status === "NONE") {
            switch (currentStep) {
                case 1:
                    return <PersonalDataForm
                        contract={contract}
                        setCurrentStep={goToNextStep}
                        previousStep={goToPreviousStep}
                        submitButtonText={submitButtonText}
                    />;
                case 2:
                    return <AdditionalDataForm
                        contract={contract}
                        setCurrentStep={goToNextStep}
                        previousStep={goToPreviousStep}
                        submitButtonText={submitButtonText}
                    />;
                case 3:
                    return <FormSummary
                        contract={contract}
                        setCurrentStep={goToNextStep}
                        previousStep={goToPreviousStep}
                        submitButtonText={submitButtonText}
                    />;
                default:
                    return <div>
                        <h1>Ungültiger Schritt</h1>
                    </div>;
            }
        } else {
            return <div>
                <h3>Vertrag ist bereits behandelt für das kommende Jahr.</h3>
            </div>;
        }
    };

    return (
        <div className="container mt-5">
            <div className="mb-3">
                <div className="row">
                    <div className="col-12" style={{textAlign: "center", marginBottom: "3.5vh"}}>
                        <h2>Mitverwendungsantrag</h2>
                    </div>
                </div>
                {renderStep()}
            </div>
        </div>
    );
};

export default ContractContainerPage;
