import { configureStore } from '@reduxjs/toolkit';
import { persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import rootSaga from "./sagas/rootSaga";
import persistedReducer from "./reducers/rootReducer";

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: ['persist/PERSIST', 'persist/PURGE'] // Hinzufügen von 'persist/PURGE'
        }
    }).concat(sagaMiddleware)
});


sagaMiddleware.run(rootSaga);

const persistor = persistStore(store);
export { store, persistor };