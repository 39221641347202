import React, { useState } from 'react';
import './DetailCard.css';

function DetailCard({ entries: initialEntries }) {
    const [entries, setEntries] = useState(initialEntries.map(entry => ({
        ...entry,
        isEditable: false
    })));

    // Toggle the edit mode for a specific entry
    const toggleEdit = index => {
        setEntries(entries =>
            entries.map((entry, i) =>
                i === index ? { ...entry, isEditable: !entry.isEditable } : entry
            )
        );
    };

    // Handle the change of value in an editable entry
    const handleValueChange = (index, newValue) => {
        setEntries(entries =>
            entries.map((entry, i) =>
                i === index ? { ...entry, value: newValue } : entry
            )
        );
    };

    return (
        <div className="container my-5">
            <h2 className="text-center my-4">Details</h2>
            <div className="card detail-card border-0 shadow-sm">
                <div className="card-body">
                    {entries.map((entry, index) => (
                        <div key={index} className="row detail-content py-2 align-items-center">
                            <div className="col-12 col-md-3 detail-label font-weight-bold">
                                {entry.label}:
                            </div>
                            <div className="col-12 col-md-9 detail-value" onClick={() => toggleEdit(index)}>
                                {entry.isEditable ? (
                                    <input
                                        type="text"
                                        className="form-control" // Added class for styling
                                        value={entry.value}
                                        onChange={e => handleValueChange(index, e.target.value)}
                                        onBlur={() => toggleEdit(index)}
                                        autoFocus
                                    />
                                ) : (
                                    <span>{entry.value}</span>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default DetailCard;
