import React from 'react';
import { Link } from 'react-router-dom';
import {FaBuilding, FaPlusSquare, FaUserPlus} from 'react-icons/fa';
import './InstituteDashboard.css';

function InstituteDashboard() {
    return (
        <div className="dashboard-container">
            <h2 className="dashboard-title">Institute-Dashboard</h2>
            <div className="dashboard-links">
                <Link to="/mdb/institute/overview" className="dashboard-link">
                    <FaBuilding className="icon" />
                    Institutsübersicht
                </Link>
                <Link to="/mdb/institute/employment/new" className="dashboard-link">
                    <FaUserPlus className="icon" />
                    Mitverwendung anlegen
                </Link>
            </div>
        </div>
    );
}

export default InstituteDashboard;

