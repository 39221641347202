import React, {useEffect, useState} from "react";
import ProfileForm from "./ProfileForm"; // Angenommen, dies ist Ihre Komponente für das Aufgabenprofil
import ContractForm from "./ContractForm"; // Ihre ContractForm-Komponente
import EmploymentForm from "./EmploymentForm";
import FormSummary from "../FormSummary";
import {useDispatch, useSelector} from "react-redux";
import {getContract} from "../redux/actions/actions";
import {useParams} from "react-router-dom";

const EmploymentFormContainer = () => {
    const { id} = useParams()
    const [currentStep, setCurrentStep] = useState(1);
    const dispatch = useDispatch();
    const contract = useSelector(state => state.jointPower.contract);

    useEffect(() => {
        if (!('ID' in contract) && contract.ID !== id) {
            dispatch(getContract(id));
        }
    }, [dispatch, contract, id]);

    const goToPreviousStep = () => setCurrentStep(currentStep - 1);
    const goToNextStep = () => setCurrentStep(currentStep + 1);
    const renderStep = () => {
        let submitButtonText = "Weiter";
        if (currentStep === 4) {
            submitButtonText = "Speichern";
        }
        switch (currentStep) {
            case 1:
                return <EmploymentForm
                    contract={contract}
                    setCurrentStep={goToNextStep}
                    previousStep=""
                    submitButtonText={submitButtonText}
                />;
            case 2:
                return <ProfileForm
                    setCurrentStep={goToNextStep}
                    previousStep={goToPreviousStep}
                    submitButtonText={submitButtonText}
                />;
            case 3:
                return <ContractForm
                    setCurrentStep={goToNextStep}
                    previousStep={goToPreviousStep}
                    submitButtonText={submitButtonText}
                />;
            case 4:
                return <FormSummary
                    contract={contract}
                    setCurrentStep={goToNextStep}
                    previousStep={goToPreviousStep}
                    submitButtonText={submitButtonText}
                />;
            default:
                return <div>Unbekannter Schritt</div>;
        }
    };

    if (contract.Status === "INSTITUTE") {
        return (
            <div className="container mt-5">
                <div className="mb-3">
                    <div className="row">
                        <div className="col-12" style={{textAlign: "center", marginBottom: "3.5vh"}}>
                            <h2>Mitverwendungsantrag</h2>
                            <div>Der Vertrag wurde bereits eingereicht.</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    return (
        <div className="container mt-5">
            <div className="mb-3">
                <div className="row">
                    <div className="col-12" style={{textAlign: "center", marginBottom: "3.5vh"}}>
                        <h2>Mitverwendungsantrag</h2>
                    </div>
                </div>
                {renderStep()}
            </div>
        </div>
    );

};

export default EmploymentFormContainer;

