import {
    SET_EMAIL,
    SET_USERGROUPS,
    SET_AUTHTOKEN, RESET_STORE,
} from '../actions/actions';

const initialState = {
    email: '',
    userGroups: [],
    authToken: ''
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case RESET_STORE:
            return initialState;
        case SET_USERGROUPS:
            return { ...state, userGroups: action.payload };
        case SET_EMAIL:
            return { ...state, email: action.payload };
        case SET_AUTHTOKEN:
            return { ...state, authToken: action.payload };
        default:
            return state;
    }
};

export default userReducer;
