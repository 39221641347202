import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext';

const ProtectedRoute = ({ allowedRoles, children }) => {
    const { userGroups } = useAuth(); // Hier nehmen Sie die Rolle des Benutzers aus dem AuthContext
    if (Array.isArray(userGroups) && !userGroups.some(role => allowedRoles.includes(role))) {
        return <Navigate to="/" replace />;
    }

    return children; // Gibt die Kinderkomponenten zurück, wenn der Benutzer berechtigt ist
};

export default ProtectedRoute;