import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {toast} from "react-toastify";
import PasswordChecklist from "react-password-checklist"
import {useSelector} from "react-redux";

const ResetPasswordForm = ({ previousStep, showPassword, emailAddress, setResetForm }) => {
    const navigate = useNavigate();
    const [email, setEmail] = useState(emailAddress || '');
    const [password, setPassword] = useState('');
    const [passwordAgain, setPasswordAgain] = useState('');
    const [isPasswordValid, setIsPasswordValid] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (showPassword && !isPasswordValid) {
            toast("Passwort entspricht nicht den Anforderungen", { type: "danger" });
            return;
        }

        const response = await fetch(`${process.env.REACT_APP_API_URL}/login-api/resetPassword`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email, password })
        });
        const data = await response.json();
        if (response.ok && !data.error) {
            toast("Passwort erfolgreich zurückgesetzt", { type: "success" });
            if (!previousStep) {
                if (setResetForm) {
                    setResetForm(false);
                }
                navigate('/');
                return;
            }
            previousStep();
        }
        if (response.ok && data.error) {
            toast(data.error, { type: "danger" });
        }
        if (!response.ok) {
            toast("Fehler beim Zurücksetzen des Passworts", { type: "danger" });
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group text-start">
                {!showPassword && (
                <>
                    <label htmlFor="email" className="form-label">E-Mail-Adresse</label>
                    <input
                        type="email"
                        placeholder="Email"
                        className="form-control"
                        id="email"
                        value={email} // Stellen Sie sicher, dass value auf den Zustand verweist
                        onChange={(e) => setEmail(e.target.value)} // Aktualisieren Sie den Zustand bei Änderung
                    />
                </>
                )}
                {showPassword && (
                    <>
                        <label htmlFor="password" className="form-label">Passwort</label>
                        <input
                            type="password"
                            className="form-control my-2"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                        />
                        <label htmlFor="passwordAgain" className="form-label">Passwort wiederholen</label>
                        <input
                            type="password"
                            className="form-control my-2"
                            value={passwordAgain}
                            onChange={e => setPasswordAgain(e.target.value)}
                        />
                        <PasswordChecklist
                            rules={["minLength", "specialChar", "number", "capital", "match"]}
                            minLength={5}
                            value={password}
                            valueAgain={passwordAgain}
                            onChange={(isValid) => setIsPasswordValid(isValid)}
                        />
                    </>
                )}
            </div>
            <div className="mb-2 py-2 text-end">
                {previousStep && (
                <button type="button" className="btn btn-link" onClick={() => previousStep()}>Zurück</button>
                )}
                <button type="submit" className="btn btn-primary">Reset</button>
            </div>
        </form>
    );
};

export default ResetPasswordForm;
