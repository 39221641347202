import React, {useEffect, useRef, useState} from 'react';
import SignatureCanvas from 'react-signature-canvas';
import {
    FaUser,
    FaBuilding,
    FaClock,
    FaPhone,
    FaEnvelope,
    FaBirthdayCake,
    FaSchool,
    FaChalkboardTeacher,
    FaIdCard,
    FaCalendarAlt,
    FaFlag,
    FaBalanceScale,
    FaBriefcase,
    FaArrowLeft,
    FaDownload,
    FaFile, FaCalendar
} from 'react-icons/fa';
import './FormSummary.css';
import './error.css'
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {getEmployee, getEmployments, updateContract} from "./redux/actions/actions";

const FormSummary = ({ contract, setCurrentStep, previousStep, submitButtonText }) => {
    const { handleSubmit } = useForm({});
    const dispatch = useDispatch();
    const employee = useSelector(state => state.jointPower.employee);
    const employments = useSelector(state => state.jointPower.employments);
    const userGroups = useSelector(state => state.user.userGroups);
    const authToken = useSelector(state => state.user.authToken);
    const navigate = useNavigate();

    const isInstitute = userGroups.includes('institute') || userGroups.includes('assistant');

    const [signature, setSignature] = useState(null);
    const sigPad = useRef({});

    useEffect(() => {
        if (employee.ID !== contract.EmployeeID) {
            dispatch(getEmployee({contractID: contract.ID}));
        }
        if (employee.ID && (employments.length === 0 || employments[0]?.EmployeeID !== employee.ID)) {
            dispatch(getEmployments(employee.ID));
        }
    }, [dispatch, employee.ID, contract.ID, employments?.length]);

    // Funktion, um die Signatur zu speichern
    const saveSignature = () => {
        setSignature(sigPad.current.getTrimmedCanvas().toDataURL('image/png'));
    };

    const clearSignature = () => {
        sigPad.current.clear();
        setSignature(null);
    };

    const onSubmit = async () => {
        const updatedContract = {...contract, Signature: signature};
        dispatch(updateContract(updatedContract));
        if (isInstitute) {
            navigate("/mdb/institute/overview");
        } else {
            navigate("/mdb/staff");
        }
    }

    const downloadFile = async (token, index) => {
        const fileToDownload = employee.EmployeeFiles[index];
        let apiUrl;
        let fileName;

        apiUrl = `${process.env.REACT_APP_API_URL}/employee-api/getEmployeeFile/${fileToDownload.ID}`;
        fileName = `${fileToDownload.File.Name}`;

        const response = await fetch(apiUrl, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            method: 'GET'
        });

        if (!response.ok) {
            return;
        }

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
    };

    return (
        <div className="form-summary-container">
            <h3 className="summary-title">Übersicht</h3>
            <div className="summary-item">
                <FaEnvelope/> <strong>Email:</strong> {employee.InitialEmail}
            </div>
            {employee.Title && (
                <div className="summary-item">
                    <FaIdCard/> <strong>Titel:</strong> {employee.Title}
                </div>
            )}
            <div className="summary-item">
                <FaUser/> <strong>Name:</strong> {employee.GivenName} {employee.FamilyName}
            </div>
            {employee.TitleAfter && (
                <div className="summary-item">
                    <FaIdCard/> <strong>Titel danach:</strong> {employee.TitleAfter}
                </div>
            )}
            {employee.TitleOfficial && (
                <div className="summary-item">
                    <FaIdCard/> <strong>Amtstitel (Berufstitel):</strong> {employee.TitleOfficial}
                </div>
            )}
            {employee.Birthday && (
                <div className="summary-item">
                    <FaBirthdayCake/> <strong>Geburtstag:</strong> {employee.Birthday}
                </div>
            )}
            {employee.SocialSecurityNumber && (
                <div className="summary-item">
                    <FaEnvelope/> <strong>Sozialversicherungsnummer:</strong> {employee.SocialSecurityNumber}
                </div>
            )}
            {employee.PhoneNumber && (
                <div className="summary-item">
                    <FaPhone/> <strong>Telefonnummer:</strong> {employee.PhoneNumber}
                </div>
            )}
            {employee.Nationality && (
                <div className="summary-item">
                    <FaFlag/> <strong>Staatsangehörigkeit:</strong> {employee.Nationality}
                </div>
            )}
            {employee.Gender && (
                <div className="summary-item">
                    <FaUser/> <strong>Geschlecht:</strong> {employee.Gender}
                </div>
            )}
            {employee.EmployeeFiles && (
                <div className="summary-item">
                    <FaFile/> <strong>Dateien:</strong>
                    {(employee.EmployeeFiles || []).map((file, index) => (
                        <div key={index}>
                            {file.File.Name}
                            <span role="button" onClick={() => downloadFile(authToken, index)} tabIndex="0"
                                  style={{color: "blue"}}><FaDownload/></span>
                        </div>
                    ))}
                </div>
            )}
            <hr/>
            {contract.SchoolIdentificationNumber && (
                <div className="summary-item">
                    <FaSchool/> <strong>Schulkennzahl:</strong> {contract.SchoolIdentificationNumber}
                </div>
            )}
            {contract.Start && (
                <div className="summary-item">
                    <FaCalendar/> <strong>Ab:</strong> {contract.Start}
                </div>
            )}
            {contract.Start && (
                <div className="summary-item">
                    <FaCalendar/> <strong>Bis:</strong> {contract.End}
                </div>
            )}
            {contract.SchoolEmployeeNumber && (
                <div className="summary-item">
                <FaIdCard/> <strong>Personalnummer:</strong> {contract.SchoolEmployeeNumber}
                </div>
            )}
            {contract.SchoolEntryDate && (
                <div className="summary-item">
                    <FaCalendarAlt/> <strong>Eintritt (Schule):</strong> {contract.SchoolEntryDate}
                </div>
            )}
            {contract.NewOrExtension && (
                <div className="summary-item">
                    <FaCalendarAlt/> <strong>Neu/Verlängerung:</strong> {contract.NewOrExtension === "NEW" ? "Neu" : "Verlängerung"}
                </div>
            )}
            {contract.ContractOrCivil && (
                <div className="summary-item">
                    <FaBriefcase/> <strong>Beschäftigungsverhältnis:</strong> {contract.ContractOrCivil !== "NONE" && contract.ContractOrCivil === "CONTRACT" ? "Vertraglich" : "Pragmatisiert"}
                </div>
            )}
            {contract.FixedOrPermanent && (
                <div className="summary-item">
                    <FaBalanceScale/> <strong>Vertragstyp:</strong> {contract.FixedOrPermanent === "FIXED" ? "Befristet" : "Unbefristet" }
                </div>
            )}
            {contract.ClassificationType && (
                <div className="summary-item">
                    <FaIdCard/> <strong>Einstufung:</strong> {contract.ClassificationType}
                </div>
            )}
            {employments.map((employment, index) => (
                <div key={index} className="summary-item">
                    <span><FaBuilding/> <strong>Institut ({index + 1}):</strong> {employment.Institute}</span>
                    <span><FaIdCard/> <strong>Jobtitel:</strong> {employment.JobTitle}</span>
                    { employment.NumOfUnits && <span><FaClock/> <strong>Planend:</strong> {employment.NumOfUnits} {employment.UnitType}</span> }
                    { employment.NumOfUnitsTeaching && <span><FaClock/> <strong>Lehrend:</strong> {employment.NumOfUnitsTeaching} {employment.UnitTypeTeaching}</span> }
                </div>
            ))}
            {contract.TotalUnits !== "" && (
                <div className="summary-item">
                <FaIdCard/> <strong>Gesamtanzahl an der WE an der PH:</strong> {contract.TotalUnits}
                </div>
            )}
            { !isInstitute && (
                <div className="row">
                    <div className="col text-center">
                        <div className="signature-container">
                            <div className="signature-title">Unterschrift <b>(optional)</b></div>
                            <SignatureCanvas
                                ref={sigPad}
                                penColor='black'
                                canvasProps={{
                                    width: 800,
                                    height: 300,
                                    className: 'sigCanvas'
                                }}
                                onEnd={saveSignature}
                            />
                            <div>
                            <button type="button" className="btn-secondary" onClick={clearSignature}>Signatur löschen</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <form onSubmit={handleSubmit(onSubmit)} className="contract-form">
                <div className="form-navigation-buttons">
                    { previousStep && (
                        <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={previousStep}
                        >
                            <FaArrowLeft/> Zurück
                        </button>
                    )}
                    { submitButtonText && (
                        <button type="submit" className="btn btn-primary">{submitButtonText}</button>
                    )}
                </div>
            </form>
        </div>
    );
};

export default FormSummary;
