import React, { useEffect, useState } from 'react';
import './ContractsTable.css';
import {useDispatch, useSelector} from "react-redux";
import {getContracts} from "../redux/actions/actions";
import FormSummary from "../FormSummary";
import {FaInfo} from "react-icons/fa";

const ContractsTable = () => {
    const dispatch = useDispatch();
    const authToken = useSelector(state => state.user.authToken);
    const contracts = useSelector(state => state.jointPower.contracts);
    const [contractToShow, setContractToShow] = useState(null);

    useEffect(() => {
        if (!contracts.length) {
            dispatch(getContracts());
        }
    }, [dispatch, contracts]);

    const downloadFile = async (token, contractID, fileType) => {
        let apiUrl;
        let fileName;

        switch (fileType) {
            case 'PDF':
                apiUrl = `${process.env.REACT_APP_API_URL}/jointpower-api/getPDF/${contractID}`;
                fileName = "contract.pdf";
                break;
            case 'BBA':
                apiUrl = `${process.env.REACT_APP_API_URL}/jointpower-api/getBBA/${contractID}`;
                fileName = "bba.pdf";
                break;
            case 'DBD':
                apiUrl = `${process.env.REACT_APP_API_URL}/jointpower-api/getDBD/${contractID}`;
                fileName = "dbd.pdf";
                break;
            default:
                console.error("Unbekannter Dateityp");
                return;
        }

        const response = await fetch(apiUrl, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            method: 'GET'
        });

        if (!response.ok) {
            console.error(`Fehler beim Herunterladen der Datei: ${fileType}`);
            return;
        }

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
    };

    const setContractToShowHandler = (contract) => {
        console.log(contract);
        setContractToShow({ ...contract });
    };

    const resetContractToShow = () => {
        setContractToShow(null);
    }

    return (
        <>
            {!contractToShow && (
                <div className="container contract-details">
                    <h2 className="text-center my-4">Vertragsübersicht</h2>
                    <div className="row header-row d-none d-md-flex">
                        <div className="col-md-3"><strong>Jahr</strong></div>
                        <div className="col-md-3"><strong>Status</strong></div>
                        <div className="col-md-3"><strong>Institution</strong></div>
                        <div className="col-md-3"></div>
                    </div>
                    {contracts.map((contractEntry, index) => (
                        <div key={index} className="row data-row">
                            <div className="col-12 col-md-3">{contractEntry.schoolYear && contractEntry.schoolYear.Name}</div>
                            <div className="col-12 col-md-3">
                                {contractEntry.contract.Status}
                                {contractEntry.contract.Status === 'NONE' && contractEntry.contract.RectorateDate && (
                                    <span>Abgelehnt von Rektorat</span>
                                )}
                                {contractEntry.contract.Status === 'NONE' && contractEntry.contract.SchoolDate && (
                                    <span>Abgelehnt von Schule</span>
                                )}
                                {contractEntry.contract.Status === 'NONE' && contractEntry.contract.SchoolDate && (
                                    <span>Abgelehnt von Schule</span>
                                )}
                            </div>
                            <div className="col-12 col-md-3">
                                {contractEntry.employments.map((employmentEntry, empIndex) => (
                                    <div key={empIndex}>
                                        {employmentEntry.orgUnit.Name} {employmentEntry.employment.NumOfUnits} {employmentEntry.employment.UnitType} {employmentEntry.employment.JobTitle} {employmentEntry.employment.IsTeaching ? ' Lehre' : ' Planung'}
                                    </div>
                                ))}
                            </div>
                            <div className="col-12 col-md-3">
                                {contractEntry.contract.Status === 'RECTORATE' && (
                                    <>
                                        <span className="links">
                                            <a
                                                onClick={() => downloadFile(authToken, contractEntry.contract.ID, 'PDF')}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="download-link"
                                            >
                                                PDF
                                            </a>
                                        </span>
                                        <span className="links">
                                            <a
                                                onClick={() => downloadFile(authToken, contractEntry.contract.ID, 'BBA')}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="download-link"
                                            >
                                                Beiblatt
                                            </a>
                                        </span>
                                    </>
                                )}
                                <span className="links">
                                    <a onClick={() => setContractToShowHandler(contractEntry.contract)}
                                       style={{color: "blue"}}><FaInfo/></a>
                                </span>
                            </div>
                        </div>
                    ))}
                </div>
            )}
            {contractToShow && (
                <div className="container contract-details">
                    <div className="row header-row d-none d-md-flex">
                        <div className="col">
                        <FormSummary contract={contractToShow} setCurrentStep="" previousStep={resetContractToShow} submitButtonText="" />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ContractsTable;
