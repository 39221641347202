import EmployeeForm from '../institute/EmployeeForm';
import { useNavigate } from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";

const EmployeeFormContainer = () => {
    return (
        <div className="container mt-5">
            <div className="mb-3">
                <div className="row">
                    <div className="col-12" style={{ textAlign: "center", marginBottom: "3.5vh" }}>
                        <h2>Institute-Dashboard</h2>
                    </div>
                </div>
                <div className="dashboard-container">
                    <EmployeeForm />
                </div>
            </div>
        </div>
    );
};

export default EmployeeFormContainer;
