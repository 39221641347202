import { call, put, takeLatest } from 'redux-saga/effects';
import {
    UPDATE_EMPLOYEE,
    UPDATE_CONTRACT,
    UPDATE_EMPLOYMENTS,
    updateEmployeeSuccess,
    updateEmployeeFailure,
    updateContractSuccess,
    updateContractFailure,
    updateEmploymentsSuccess,
    updateEmploymentsFailure,
    GET_EMPLOYEE,
    getEmployeeSuccess,
    getEmployeeFailure,
    getSchoolYearsSuccess,
    getSchoolYearsFailure,
    GET_SCHOOLYEARS,
    GET_INSTITUTES,
    getInstitutesSuccess,
    getInstitutesFailure,
    ADD_EMPLOYMENT,
    addEmploymentSuccess,
    addEmploymentFailure,
    REMOVE_EMPLOYMENT,
    removeEmploymentSuccess,
    removeEmploymentFailure,
    GET_EMPLOYMENTS,
    getEmploymentsSuccess,
    getEmploymentsFailure,
    GET_JOBPROFILE,
    getJobProfileSuccess,
    getJobProfileFailure,
    UPDATE_JOBPROFILE,
    updateJobProfileSuccess,
    updateJobProfileFailure,
    GET_FEDERALSTATES,
    getFederalStatesSuccess,
    getFederalStatesFailure,
    GET_CLASSIFICATIONTYPES,
    getClassificationTypesSuccess,
    getClassificationTypesFailure,
    GET_USAGETYPES,
    getUsageTypesSuccess,
    getUsageTypesFailure,
    GET_CONTRACTS,
    getContractsSuccess,
    getContractsFailure,
    UPDATE_EMPLOYMENTUNITS,
    updateEmploymentUnitsSuccess,
    updateEmploymentUnitsFailure,
    CONFIRM_EMPLOYMENT,
    confirmEmploymentSuccess,
    confirmEmploymentFailure,
    CONFIRM_CONTRACT,
    confirmContractSuccess,
    confirmContractFailure,
    GET_CONTRACT,
    getContractSuccess,
    getContractFailure,
    CREATE_EMPLOYEE,
    createEmployeeSuccess,
    createEmployeeFailure,
    setEmployee,
    addEmployeeFilesSuccess,
    addEmployeeFilesFailure,
    ADD_EMPLOYEE_FILES,
    REMOVE_EMPLOYEE_FILE,
    removeEmployeeFileSuccess, removeEmployeeFileFailure, DELETE_CONTRACT, deleteContractSuccess, deleteContractFailure,
} from '../actions/actions';

// Beispiel einer API Funktion zum Aktualisieren von Daten
async function apiCall(url, method, data = null) {
    const headers = new Headers({
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        'Content-Type': 'application/json'
    });

    const config = {
        method: method,
        headers: headers,
    };

    if (data) {
        config.body = JSON.stringify(data);
    }

    try {
        const response = await fetch(url, config);
        if (!response.ok) {
            throw new Error('API-Aufruf fehlgeschlagen');
        }
        return await response.json();
    } catch (error) {
        throw error;
    }
}

async function fetchEmployee(data) {
    return apiCall(`${process.env.REACT_APP_API_URL}/employee-api/getEmployee`, 'POST', data);
}
async function fetchSchoolYears(email) {
    return apiCall(`${process.env.REACT_APP_API_URL}/jointpower-api/getSchoolYears`, 'GET');
}
async function fetchContract(contractID) {
    return apiCall(`${process.env.REACT_APP_API_URL}/employment-api/getContract/${contractID}`, 'GET');
}
async function fetchInstitutes() {
    return apiCall(`${process.env.REACT_APP_API_URL}/jointpower-api/getInstitutes`, 'GET');
}
async function fetchJobProfile(employeeID) {
    return apiCall(`${process.env.REACT_APP_API_URL}/employment-api/getJobProfile/${employeeID}`, 'GET');
}
async function fetchEmployments(employeeID) {
    return apiCall(`${process.env.REACT_APP_API_URL}/employment-api/getEmployments/${employeeID}`, 'GET');
}
async function fetchFederalStates(employeeID) {
    return apiCall(`${process.env.REACT_APP_API_URL}/jointpower-api/getFederalStates`, 'GET');
}
async function fetchClassificationTypes() {
    return apiCall(`${process.env.REACT_APP_API_URL}/jointpower-api/getClassificationTypes`, 'GET');
}
async function fetchUsageTypes() {
    return apiCall(`${process.env.REACT_APP_API_URL}/jointpower-api/getUsageTypes`, 'GET');
}
async function fetchContracts(data) {
    return apiCall(`${process.env.REACT_APP_API_URL}/employment-api/getContracts`, 'POST', data);
}
async function createEmployee(data) {
    return apiCall(`${process.env.REACT_APP_API_URL}/employee-api/createEmployee`, 'POST', data);
}
async function updateEmployee(data) {
    return apiCall(`${process.env.REACT_APP_API_URL}/employee-api/updateEmployee`, 'POST', data);
}
async function updateContract(data) {
    return apiCall(`${process.env.REACT_APP_API_URL}/employment-api/updateContract`, 'POST', data);
}
async function removeContract(contract) {
    return apiCall(`${process.env.REACT_APP_API_URL}/employment-api/removeContract`, 'POST', contract);
}
async function updateEmployments(employments) {
    return apiCall(`${process.env.REACT_APP_API_URL}/employment-api/updateEmployments`, 'POST', employments);
}
async function updateEmploymentUnits(data) {
    return apiCall(`${process.env.REACT_APP_API_URL}/employment-api/updateEmploymentUnits`, 'POST', data);
}
async function updateJobProfile(jobProfile) {
    return apiCall(`${process.env.REACT_APP_API_URL}/employment-api/updateJobProfile`, 'POST', jobProfile);
}
async function addEmployment(employeeID) {
    return apiCall(`${process.env.REACT_APP_API_URL}/employment-api/addEmployment/${employeeID}`, 'GET');
}
async function confirmEmployment(data) {
    return apiCall(`${process.env.REACT_APP_API_URL}/employment-api/confirmEmployment`, 'POST', data);
}
async function confirmContract(data) {
    return apiCall(`${process.env.REACT_APP_API_URL}/employment-api/confirmContract`, 'POST', data);
}
async function removeEmployment(employmentID) {
    return apiCall(`${process.env.REACT_APP_API_URL}/employment-api/removeEmployment/${employmentID}`, 'GET');
}
async function updateEmployeeFiles(data) {
    return apiCall(`${process.env.REACT_APP_API_URL}/employee-api/updateEmployeeFiles`, 'POST', data);
}
async function deleteEmployeeFile(data) {
    return apiCall(`${process.env.REACT_APP_API_URL}/employee-api/removeEmployeeFile`, 'POST', data);
}

function* getEmployeeSaga(action) {
    try {
        const response = yield call(fetchEmployee, action.payload);
        yield put(getEmployeeSuccess(response));
    } catch (e) {
        yield put(getEmployeeFailure(e.message));
    }
}
function* addEmploymentSaga(action) {
    try {
        const response = yield call(addEmployment, action.payload);
        if (response.error) {
            throw new Error(response.error);
        }
        yield put(addEmploymentSuccess(response));
    } catch (e) {
        yield put(addEmploymentFailure(e.message));
    }
}
function* confirmEmploymentSaga(action) {
    try {
        const response = yield call(confirmEmployment, action.payload);
        if (response.error) {
            throw new Error(response.error);
        }
        yield put(confirmEmploymentSuccess(response));
    } catch (e) {
        console.log(e.message);
        yield put(confirmEmploymentFailure(e.message));
    }
}
function* confirmContractSaga(action) {
    try {
        const response = yield call(confirmContract, action.payload);
        if (response.error) {
            throw new Error(response.error);
        }
        yield put(confirmContractSuccess(response));
    } catch (e) {
        yield put(confirmContractFailure(e.message));
    }
}
function* removeEmploymentSaga(action) {
    try {
        const response = yield call(removeEmployment, action.payload);
        if (response.error) {
            throw new Error(response.error);
        }
        yield put(removeEmploymentSuccess(response));
    } catch (e) {
        yield put(removeEmploymentFailure(e.message));
    }
}
function* getSchoolYearsSaga(action) {
    try {
        const response = yield call(fetchSchoolYears, action.payload);
        yield put(getSchoolYearsSuccess(response));
    } catch (e) {
        yield put(getSchoolYearsFailure(e.message));
    }
}
function* getContractSaga(action) {
    try {
        const response = yield call(fetchContract, action.payload);
        if (response.error) {
            throw new Error(response.error);
        }
        yield put(getContractSuccess(response));
    } catch (e) {
        yield put(getContractFailure(e.message));
    }
}
function* getFederalStatesSaga(action) {
    try {
        const response = yield call(fetchFederalStates, action.payload);
        if (response.error) {
            throw new Error(response.error);
        }
        yield put(getFederalStatesSuccess(response));
    } catch (e) {
        yield put(getFederalStatesFailure(e.message));
    }
}
function* getInstitutesSaga(action) {
    try {
        const response = yield call(fetchInstitutes, action.payload);
        if (response.error) {
            throw new Error(response.error);
        }
        yield put(getInstitutesSuccess(response));
    } catch (e) {
        yield put(getInstitutesFailure(e.message));
    }
}
function* getJobProfileSaga(action) {
    try {
        const response = yield call(fetchJobProfile, action.payload);
        if (response.error) {
            throw new Error(response.error);
        }
        yield put(getJobProfileSuccess(response));
    } catch (e) {
        yield put(getJobProfileFailure(e.message));
    }
}
function* getClassificationTypesSaga(action) {
    try {
        const response = yield call(fetchClassificationTypes, action.payload);
        if (response.error) {
            throw new Error(response.error);
        }
        yield put(getClassificationTypesSuccess(response));
    } catch (e) {
        yield put(getClassificationTypesFailure(e.message));
    }
}
function* getUsageTypesSaga(action) {
    try {
        const response = yield call(fetchUsageTypes, action.payload);
        if (response.error) {
            throw new Error(response.error);
        }
        yield put(getUsageTypesSuccess(response));
    } catch (e) {
        yield put(getUsageTypesFailure(e.message));
    }
}
function* getEmploymentsSaga(action) {
    try {
        const response = yield call(fetchEmployments, action.payload);
        if (response.error) {
            throw new Error(response.error);
        }
        yield put(getEmploymentsSuccess(response));
    } catch (e) {
        yield put(getEmploymentsFailure(e.message));
    }
}
function* getContractsSaga(action) {
    try {
        const response = yield call(fetchContracts, action.payload);
        if (response.error) {
            throw new Error(response.error);
        }
        yield put(getContractsSuccess(response));
    } catch (e) {
        yield put(getContractsFailure(e.message));
    }
}
function* deleteContractSaga(action) {
    try {
        const response = yield call(removeContract, action.payload);
        if (response.error) {
            throw new Error(response.error);
        }
        yield put(deleteContractSuccess(response));
    } catch (e) {
        yield put(deleteContractFailure(e.message));
    }

}
function* createEmployeeSaga(action) {
    try {
        const response = yield call(createEmployee, action.payload);
        yield put(createEmployeeSuccess(response));
        yield put(setEmployee({}));
    } catch (e) {
        yield put(createEmployeeFailure(e.message));
    }
}
function* updateEmployeeSaga(action) {
    try {
        const response = yield call(updateEmployee, action.payload);
        yield put(updateEmployeeSuccess(response));
    } catch (e) {
        yield put(updateEmployeeFailure(e.message));
    }
}

function* updateContractSaga(action) {
    try {
        const response = yield call(updateContract, action.payload);
        if (response.error) {
            throw new Error(response.error);
        }
        yield put(updateContractSuccess(response));
    } catch (e) {
        yield put(updateContractFailure(e.message));
    }
}

function* updateEmploymentsSaga(action) {
    try {
        const response = yield call(updateEmployments, action.payload);
        yield put(updateEmploymentsSuccess(response));
    } catch (e) {
        yield put(updateEmploymentsFailure(e.message));
    }
}
function* updateEmploymentUnitsSaga(action) {
    try {
        const response = yield call(updateEmploymentUnits, action.payload);
        if (response.error) {
            throw new Error(response.error);
        }
        yield put(updateEmploymentUnitsSuccess(response));
    } catch (e) {
        yield put(updateEmploymentUnitsFailure(e.message));
    }
}


function* updateJobProfileSaga(action) {
    try {
        const response = yield call(updateJobProfile, action.payload);
        yield put(updateJobProfileSuccess(response));
    } catch (e) {
        yield put(updateJobProfileFailure(e.message));
    }
}

function* addEmployeeFilesSaga(action) {
    try {
        const response = yield call(updateEmployeeFiles, action.payload);
        console.log("XXXXXXXXXX");
        console.log(response);
        yield put(addEmployeeFilesSuccess(response));
    } catch (e) {
        yield put(addEmployeeFilesFailure(e.message));
    }
}

function* removeEmployeeFileSaga(action) {
    try {
        const response = yield call(deleteEmployeeFile, action.payload);
        yield put(removeEmployeeFileSuccess(response));
    } catch (e) {
        yield put(removeEmployeeFileFailure(e.message));
    }
}


// Root Saga
export default function* watchSagas() {
    yield takeLatest(GET_EMPLOYEE, getEmployeeSaga);
    yield takeLatest(ADD_EMPLOYMENT, addEmploymentSaga);
    yield takeLatest(REMOVE_EMPLOYMENT, removeEmploymentSaga);
    yield takeLatest(CONFIRM_EMPLOYMENT, confirmEmploymentSaga);
    yield takeLatest(CONFIRM_CONTRACT, confirmContractSaga);
    yield takeLatest(GET_INSTITUTES, getInstitutesSaga);
    yield takeLatest(GET_EMPLOYMENTS, getEmploymentsSaga);
    yield takeLatest(GET_SCHOOLYEARS, getSchoolYearsSaga);
    yield takeLatest(GET_CONTRACT, getContractSaga);
    yield takeLatest(DELETE_CONTRACT, deleteContractSaga);
    yield takeLatest(GET_FEDERALSTATES, getFederalStatesSaga);
    yield takeLatest(GET_JOBPROFILE, getJobProfileSaga);
    yield takeLatest(GET_CLASSIFICATIONTYPES, getClassificationTypesSaga);
    yield takeLatest(GET_USAGETYPES, getUsageTypesSaga);
    yield takeLatest(GET_CONTRACTS, getContractsSaga);
    yield takeLatest(CREATE_EMPLOYEE, createEmployeeSaga);
    yield takeLatest(UPDATE_EMPLOYEE, updateEmployeeSaga);
    yield takeLatest(UPDATE_JOBPROFILE, updateJobProfileSaga);
    yield takeLatest(UPDATE_CONTRACT, updateContractSaga);
    yield takeLatest(UPDATE_EMPLOYMENTS, updateEmploymentsSaga);
    yield takeLatest(UPDATE_EMPLOYMENTUNITS, updateEmploymentUnitsSaga);
    yield takeLatest(ADD_EMPLOYEE_FILES, addEmployeeFilesSaga);
    yield takeLatest(REMOVE_EMPLOYEE_FILE, removeEmployeeFileSaga);
}
