export const GET_EMPLOYEE = 'GET_EMPLOYEE';
export const GET_EMPLOYEE_SUCCESS = 'GET_EMPLOYEE_SUCCESS';
export const GET_EMPLOYEE_FAILURE = 'GET_EMPLOYEE_FAILURE';
export const SET_EMPLOYEE = 'SET_EMPLOYEE';
export const ADD_EMPLOYMENT = 'ADD_EMPLOYMENT';
export const ADD_EMPLOYMENT_SUCCESS = 'ADD_EMPLOYMENT_SUCCESS';
export const ADD_EMPLOYMENT_FAILURE = 'ADD_EMPLOYMENT_FAILURE';
export const REMOVE_EMPLOYMENT = 'REMOVE_EMPLOYMENT';
export const REMOVE_EMPLOYMENT_SUCCESS = 'REMOVE_EMPLOYMENT_SUCCESS';
export const REMOVE_EMPLOYMENT_FAILURE = 'REMOVE_EMPLOYMENT_FAILURE';
export const GET_INSTITUTES = 'GET_INSTITUTES';
export const GET_INSTITUTES_SUCCESS = 'GET_INSTITUTES_SUCCESS';
export const GET_INSTITUTES_FAILURE = 'GET_INSTITUTES_FAILURE';
export const GET_EMPLOYMENTS = 'GET_EMPLOYMENTS';
export const GET_EMPLOYMENTS_SUCCESS = 'GET_EMPLOYMENTS_SUCCESS';
export const GET_EMPLOYMENTS_FAILURE = 'GET_EMPLOYMENTS_FAILURE';
export const GET_SCHOOLYEARS = 'GET_SCHOOLYEARS';
export const GET_SCHOOLYEARS_SUCCESS = 'GET_SCHOOLYEARS_SUCCESS';
export const GET_SCHOOLYEARS_FAILURE = 'GET_SCHOOLYEARS_FAILURE';
export const GET_FEDERALSTATES = 'GET_FEDERALSTATES';
export const GET_FEDERALSTATES_SUCCESS = 'GET_FEDERALSTATES_SUCCESS';
export const GET_FEDERALSTATES_FAILURE = 'GET_FEDERALSTATES_FAILURE';
export const GET_JOBPROFILE = 'GET_JOBPROFILE';
export const GET_JOBPROFILE_SUCCESS = 'GET_JOBPROFILE_SUCCESS';
export const GET_JOBPROFILE_FAILURE = 'GET_JOBPROFILE_FAILURE';
export const GET_CLASSIFICATIONTYPES = 'GET_CLASSIFICATIONTYPES';
export const GET_CLASSIFICATIONTYPES_SUCCESS = 'GET_CLASSIFICATIONTYPES_SUCCESS';
export const GET_CLASSIFICATIONTYPES_FAILURE = 'GET_CLASSIFICATIONTYPES_FAILURE';
export const GET_USAGETYPES = 'GET_USAGETYPES';
export const GET_USAGETYPES_SUCCESS = 'GET_USAGETYPES_SUCCESS';
export const GET_USAGETYPES_FAILURE = 'GET_USAGETYPES_FAILURE';
export const GET_CONTRACT = 'GET_CONTRACT';
export const GET_CONTRACT_SUCCESS = 'GET_CONTRACT_SUCCESS';
export const GET_CONTRACT_FAILURE = 'GET_CONTRACT_FAILURE';
export const GET_CONTRACTS = 'GET_CONTRACTS';
export const GET_CONTRACTS_SUCCESS = 'GET_CONTRACTS_SUCCESS';
export const GET_CONTRACTS_FAILURE = 'GET_CONTRACTS_FAILURE';
export const CREATE_EMPLOYEE = 'CREATE_EMPLOYEE';
export const CREATE_EMPLOYEE_SUCCESS = 'CREATE_EMPLOYEE_SUCCESS';
export const CREATE_EMPLOYEE_FAILURE = 'CREATE_EMPLOYEE_FAILURE';
export const UPDATE_EMPLOYEE = 'UPDATE_EMPLOYEE';
export const UPDATE_EMPLOYEE_SUCCESS = 'UPDATE_EMPLOYEE_SUCCESS';
export const UPDATE_EMPLOYEE_FAILURE = 'UPDATE_EMPLOYEE_FAILURE';
export const UPDATE_JOBPROFILE = 'UPDATE_JOBPROFILE';
export const UPDATE_JOBPROFILE_SUCCESS = 'UPDATE_JOBPROFILE_SUCCESS';
export const UPDATE_JOBPROFILE_FAILURE = 'UPDATE_JOBPROFILE_FAILURE';
export const UPDATE_CONTRACT = 'UPDATE_CONTRACT';
export const UPDATE_CONTRACT_SUCCESS = 'UPDATE_CONTRACT_SUCCESS';
export const UPDATE_CONTRACT_FAILURE = 'UPDATE_CONTRACT_FAILURE';
export const CONFIRM_EMPLOYMENT = 'CONFIRM_EMPLOYMENT';
export const CONFIRM_EMPLOYMENT_SUCCESS = 'CONFIRM_EMPLOYMENT_SUCCESS';
export const CONFIRM_EMPLOYMENT_FAILURE = 'CONFIRM_EMPLOYMENT_FAILURE';
export const CONFIRM_CONTRACT = 'CONFIRM_CONTRACT';
export const CONFIRM_CONTRACT_SUCCESS = 'CONFIRM_CONTRACT_SUCCESS';
export const CONFIRM_CONTRACT_FAILURE = 'CONFIRM_CONTRACT_FAILURE';
export const UPDATE_EMPLOYMENTS = 'UPDATE_EMPLOYMENTS';
export const UPDATE_EMPLOYMENTS_SUCCESS = 'UPDATE_EMPLOYMENTS_SUCCESS';
export const UPDATE_EMPLOYMENTS_FAILURE = 'UPDATE_EMPLOYMENTS_FAILURE';
export const UPDATE_EMPLOYMENTUNITS = 'UPDATE_EMPLOYMENTUNITS';
export const UPDATE_EMPLOYMENTUNITS_SUCCESS = 'UPDATE_EMPLOYMENTUNITS_SUCCESS';
export const UPDATE_EMPLOYMENTUNITS_FAILURE = 'UPDATE_EMPLOYMENTUNITS_FAILURE';

export const UPDATE_SCHOOLYEAR = 'UPDATE_SCHOOLYEAR';
export const UPDATE_SCHOOLYEAR_SUCCESS = 'UPDATE_SCHOOLYEAR_SUCCESS';
export const UPDATE_SCHOOLYEAR_FAILURE = 'UPDATE_SCHOOLYEAR_FAILURE';
export const SHOW_TOAST = 'SHOW_TOAST';
export const SET_USERGROUPS = 'SET_USERGROUPS';
export const SET_EMAIL = 'SET_EMAIL';
export const SET_AUTHTOKEN = 'SET_AUTHTOKEN';

export const RESET_STORE = 'RESET_STORE';
export const ADD_EMPLOYEE_FILES = 'ADD_EMPLOYEE_FILES';
export const ADD_EMPLOYEE_FILES_SUCCESS = 'ADD_EMPLOYEE_FILES_SUCCESS';
export const ADD_EMPLOYEE_FILES_FAILURE = 'ADD_EMPLOYEE_FILES_FAILURE';
export const REMOVE_EMPLOYEE_FILE = 'REMOVE_EMPLOYEE_FILE';
export const REMOVE_EMPLOYEE_FILE_SUCCESS = 'REMOVE_EMPLOYEE_FILE_SUCCESS';
export const REMOVE_EMPLOYEE_FILE_FAILURE = 'REMOVE_EMPLOYEE_FILE_FAILURE';
export const DELETE_CONTRACT = 'DELETE_CONTRACT';
export const DELETE_CONTRACT_SUCCESS = 'DELETE_CONTRACT_SUCCESS';
export const DELETE_CONTRACT_FAILURE = 'DELETE_CONTRACT_FAILURE';

export const resetStore = () => ({
    type: RESET_STORE
});
export const addEmployeeFiles = (employeeID, newFileIDs) => {
    return {
        type: ADD_EMPLOYEE_FILES,
        payload: { employeeID, newFileIDs },
    };
};
export const addEmployeeFilesSuccess = data => ({
    type: ADD_EMPLOYEE_FILES_SUCCESS,
    payload: data
});
export const addEmployeeFilesFailure = data => ({
    type: ADD_EMPLOYEE_FILES_FAILURE,
    payload: data
});

export const removeEmployeeFile = (employeeID, fileID) => ({
    type: REMOVE_EMPLOYEE_FILE,
    payload: { employeeID, fileID }
});
export const removeEmployeeFileSuccess = data => ({
    type: REMOVE_EMPLOYEE_FILE_SUCCESS,
    payload: data
});
export const removeEmployeeFileFailure = data => ({
    type: REMOVE_EMPLOYEE_FILE_FAILURE,
    payload: data
});

// Action Creators
export const getContract = id => ({
    type: GET_CONTRACT,
    payload: id
});
export const getContractSuccess = contract => ({
    type: GET_CONTRACT_SUCCESS,
    payload: contract
});
export const getContractFailure = data => ({
    type: GET_CONTRACT_FAILURE,
    payload: data
});
export const deleteContract = contract => ({
    type: DELETE_CONTRACT,
    payload: contract
});
export const deleteContractSuccess = contract => ({
    type: DELETE_CONTRACT_SUCCESS,
    payload: contract
});
export const deleteContractFailure = data => ({
    type: DELETE_CONTRACT_FAILURE,
    payload: data
});
export const getEmployee = data => ({
    type: GET_EMPLOYEE,
    payload: data
});
export const getEmployeeSuccess = employee => ({
    type: GET_EMPLOYEE_SUCCESS,
    payload: employee
});
export const getEmployeeFailure = data => ({
    type: GET_EMPLOYEE_FAILURE,
    payload: data
});
export const setEmployee = employee => ({
    type: SET_EMPLOYEE,
    payload: employee
});
export const addEmployment = employeeID => ({
    type: ADD_EMPLOYMENT,
    payload: employeeID
});
export const addEmploymentSuccess = employment => ({
    type: ADD_EMPLOYMENT_SUCCESS,
    payload: employment
});
export const addEmploymentFailure = data => ({
    type: ADD_EMPLOYMENT_FAILURE,
    payload: data
});
export const confirmEmployment = (employmentID, contractID, status) => ({
    type: CONFIRM_EMPLOYMENT,
    payload: { employmentID, contractID, status }
});
export const confirmEmploymentSuccess = employment => ({
    type: CONFIRM_EMPLOYMENT_SUCCESS,
    payload: employment
});
export const confirmEmploymentFailure = data => ({
    type: CONFIRM_EMPLOYMENT_FAILURE,
    payload: data
});
export const confirmContract = (contractID, status, comment) => ({
    type: CONFIRM_CONTRACT,
    payload: { contractID, status, comment }
});
export const confirmContractSuccess = contract => ({
    type: CONFIRM_CONTRACT_SUCCESS,
    payload: contract
});
export const confirmContractFailure = data => ({
    type: CONFIRM_CONTRACT_FAILURE,
    payload: data
});
export const removeEmployment = employmentID => ({
    type: REMOVE_EMPLOYMENT,
    payload: employmentID
});
export const removeEmploymentSuccess = employmentID => ({
    type: REMOVE_EMPLOYMENT_SUCCESS,
    payload: employmentID
});
export const removeEmploymentFailure = data => ({
    type: REMOVE_EMPLOYMENT_FAILURE,
    payload: data
});
export const getInstitutes = () => ({
    type: GET_INSTITUTES,
});

export const getInstitutesSuccess = institutes => ({
    type: GET_INSTITUTES_SUCCESS,
    payload: institutes
});

export const getInstitutesFailure = data => ({
    type: GET_INSTITUTES_FAILURE,
    payload: data
});
export const getEmployments = (employeeID) => ({
    type: GET_EMPLOYMENTS,
    payload: employeeID
});

export const getEmploymentsSuccess = employments => ({
    type: GET_EMPLOYMENTS_SUCCESS,
    payload: employments
});

export const getEmploymentsFailure = data => ({
    type: GET_EMPLOYMENTS_FAILURE,
    payload: data
});

export const getSchoolYears = () => ({
    type: GET_SCHOOLYEARS
});
export const getSchoolYearsSuccess = schoolYears => ({
    type: GET_SCHOOLYEARS_SUCCESS,
    payload: schoolYears
});
export const getSchoolYearsFailure = schoolYears => ({
    type: GET_SCHOOLYEARS_FAILURE,
    payload: schoolYears
});
export const getFederalStates = () => ({
    type: GET_FEDERALSTATES
});
export const getFederalStatesSuccess = federalStates => ({
    type: GET_FEDERALSTATES_SUCCESS,
    payload: federalStates
});
export const getFederalStatesFailure = data => ({
    type: GET_FEDERALSTATES_FAILURE,
    payload: data
});
export const getJobProfile = employeeID => ({
    type: GET_JOBPROFILE,
    payload: employeeID
});
export const getJobProfileSuccess = jobProfile => ({
    type: GET_JOBPROFILE_SUCCESS,
    payload: jobProfile
});
export const getJobProfileFailure = data => ({
    type: GET_JOBPROFILE_FAILURE,
    payload: data
});
export const getClassificationTypes = () => ({
    type: GET_CLASSIFICATIONTYPES
});
export const getClassificationTypesSuccess = classificationTypes => ({
    type: GET_CLASSIFICATIONTYPES_SUCCESS,
    payload: classificationTypes
});
export const getClassificationTypesFailure = data => ({
    type: GET_CLASSIFICATIONTYPES_FAILURE,
    payload: data
});
export const getUsageTypes = () => ({
    type: GET_USAGETYPES
});
export const getUsageTypesSuccess = usageTypes => ({
    type: GET_USAGETYPES_SUCCESS,
    payload: usageTypes
});
export const getUsageTypesFailure = data => ({
    type: GET_USAGETYPES_FAILURE,
    payload: data
});
export const getContracts = data => ({
    type: GET_CONTRACTS,
    payload: data
});
export const getContractsSuccess = contracts => ({
    type: GET_CONTRACTS_SUCCESS,
    payload: contracts
});
export const getContractsFailure = data => ({
    type: GET_CONTRACTS_FAILURE,
    payload: data
});
export const updateJobProfile = jobProfile => ({
    type: UPDATE_JOBPROFILE,
    payload: jobProfile
});
export const updateJobProfileSuccess = jobProfile => ({
    type: UPDATE_JOBPROFILE_SUCCESS,
    payload: jobProfile
});
export const updateJobProfileFailure = data => ({
    type: UPDATE_JOBPROFILE_FAILURE,
    payload: data
});
export const updateContract = contract => ({
    type: UPDATE_CONTRACT,
    payload: contract
});
// Aktion, wenn das Aktualisieren eines Vertrags erfolgreich war
export const updateContractSuccess = (contract) => {
    return {
        type: 'UPDATE_CONTRACT_SUCCESS',
        payload: contract
    };
};

// Aktion, wenn das Aktualisieren eines Vertrags fehlgeschlagen ist
export const updateContractFailure = (data) => {
    return {
        type: 'UPDATE_CONTRACT_FAILURE',
        payload: data
    };
};
export const updateEmployments = employments => ({
    type: UPDATE_EMPLOYMENTS,
    payload: employments
});

export const updateSchoolYear = schoolYear => ({
    type: UPDATE_SCHOOLYEAR,
    payload: schoolYear
});
export const createEmployee = employee => ({
    type: CREATE_EMPLOYEE,
    payload: employee
});
export const createEmployeeSuccess = (data) => {
    return {
        type: 'CREATE_EMPLOYEE_SUCCESS',
        payload: data
    };
};
export const createEmployeeFailure = (error) => {
    return {
        type: 'CREATE_EMPLOYEE_FAILURE',
        payload: error
    };
};
export const updateEmployee = employee => ({
    type: UPDATE_EMPLOYEE,
    payload: employee
});
export const updateEmployeeSuccess = (data) => {
    return {
        type: 'UPDATE_EMPLOYEE_SUCCESS',
        payload: data
    };
};

// Aktion, wenn das Aktualisieren eines Mitarbeiters fehlgeschlagen ist
export const updateEmployeeFailure = (error) => {
    return {
        type: 'UPDATE_EMPLOYEE_FAILURE',
        payload: error
    };
};
// Aktion, wenn das Aktualisieren von Beschäftigungsverhältnissen erfolgreich war
export const updateEmploymentsSuccess = (employments) => {
    return {
        type: 'UPDATE_EMPLOYMENTS_SUCCESS',
        payload: employments
    };
};

// Aktion, wenn das Aktualisieren von Beschäftigungsverhältnissen fehlgeschlagen ist
export const updateEmploymentsFailure = (error) => {
    return {
        type: 'UPDATE_EMPLOYMENTS_FAILURE',
        payload: error
    };
};
export const updateEmploymentUnits = (employmentID, numOfUnits, numOfUnitsTeaching ) => ({
    type: UPDATE_EMPLOYMENTUNITS,
    payload: { employmentID, numOfUnits, numOfUnitsTeaching }
});
export const updateEmploymentUnitsSuccess = (employment) => {
    return {
        type: 'UPDATE_EMPLOYMENTUNITS_SUCCESS',
        payload: employment
    };
};

// Aktion, wenn das Aktualisieren von Beschäftigungsverhältnissen fehlgeschlagen ist
export const updateEmploymentUnitsFailure = (error) => {
    return {
        type: 'UPDATE_EMPLOYMENTUNITS_FAILURE',
        payload: error
    };
};

// Aktion, wenn das Aktualisieren des Schuljahres erfolgreich war
export const updateSchoolYearSuccess = (data) => {
    return {
        type: 'UPDATE_SCHOOLYEAR_SUCCESS',
        payload: data
    };
};

// Aktion, wenn das Aktualisieren des Schuljahres fehlgeschlagen ist
export const updateSchoolYearFailure = (error) => {
    return {
        type: 'UPDATE_SCHOOLYEAR_FAILURE',
        payload: error
    };
};
export const showToast = (message, type) => ({
    type: 'SHOW_TOAST',
    payload: { message, type }
});
export const setUserGroups = userGroups => ({
    type: 'SET_USERGROUPS',
    payload: userGroups
});
export const setEmail = email => ({
    type: 'SET_EMAIL',
    payload: email
});
export const setAuthToken = authToken => ({
    type: 'SET_AUTHTOKEN',
    payload: authToken
});