import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import {FaUserEdit, FaFileAlt, FaFileContract} from 'react-icons/fa';
import './StaffMemberDashboard.css';
import ResetPasswordForm from "../ResetPasswordForm";
import {useDispatch, useSelector} from "react-redux";
import {getEmployee} from "../redux/actions/actions";

const StaffMemberDashboard = () => {
    const dispatch = useDispatch();
    const email = useSelector(state => state.user.email);
    const employee = useSelector(state => state.jointPower.employee);
    const [showResetForm, setShowResetForm] = useState(0);

    useEffect(() => {
        if (!employee.ID) {
            dispatch(getEmployee({email: email}));
        }
        if (employee.ID && employee.IsPasswordReset === 1) {
            setShowResetForm(0);
        }
    } , [dispatch, employee, email]);

    const resetPasswordForm = () => {
        setShowResetForm(0);
    }

    return (
        <>
            { !showResetForm && (
            <div className="dashboard-container">
                <h2 className="dashboard-title">Dashboard</h2>
                <div className="dashboard-links">
                    <Link to="/mdb/staff/contract" className="dashboard-link">
                        <FaFileAlt className="icon" />
                        <span>Mitverwendungsantrag</span>
                    </Link>
                    <Link to="/mdb/staff/contracts" className="dashboard-link">
                        <FaFileContract className="icon" />
                        <span>Übersicht</span>
                    </Link>
                    <a onClick={() => setShowResetForm(1)} className="dashboard-link">Reset Password</a>
                </div>
            </div>
            )}
            { showResetForm && (
                <div className="dashboard-container">
                    <h2 className="dashboard-title">Passwort resetten</h2>
                    <div className="row header-row d-none d-md-flex">
                        <div className="col">
                            <ResetPasswordForm previousStep={resetPasswordForm} showPassword="1" emailAddress={email}/>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default StaffMemberDashboard;
