import React, {useEffect, useState} from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import '../error.css'
import {useAuth} from "../AuthContext";
import {useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {getClassificationTypes, getUsageTypes, updateContract} from "../redux/actions/actions";
import "./ContractForm.css";

const ContractForm = ({ setCurrentStep, previousStep, submitButtonText }) => {
    const dispatch = useDispatch();
    const contract = useSelector(state => state.jointPower.contract);
    const { register, handleSubmit, watch, reset, setValue, formState: { errors } } = useForm({
        defaultValues: contract // Setzt die Standardwerte des Formulars auf die aktuellen Mitarbeiterdaten
    });
    const classificationTypes = useSelector(state => state.jointPower.classificationTypes);
    const usageTypes = useSelector(state => state.jointPower.usageTypes);
    const [schoolIdentificationSuggestions, setSchoolIdentificationSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);


    useEffect(() => {
        if (!classificationTypes.length) {
            dispatch(getClassificationTypes());
        }
        if (classificationTypes.length > 0) {
            setValue("ClassificationType", contract.ClassificationType);
        }
        if (!usageTypes.length) {
            dispatch(getUsageTypes());
        }
    }, [dispatch, classificationTypes, usageTypes, contract.ClassificationType]);

    const onSubmit = myData => {
        dispatch(updateContract(myData));
        setCurrentStep();
    }

    const fullOrPart = watch("FullOrPart");
    const contractOrCivil = watch("ContractOrCivil");

    const fetchSchoolIdentificationSuggestions = async (inputValue) => {
        if (inputValue.length < 3) { // Um unnötige Anfragen zu vermeiden
            setSchoolIdentificationSuggestions([]);
            return;
        }
        const headers = new Headers({
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
            'Content-Type': 'application/json'
        });

        const config = {
            method: 'GET',
            headers: headers,
        };
        const response = await fetch(`${process.env.REACT_APP_API_URL}/jointpower-api/getSchools/${inputValue}`, config);
        const data = await response.json();
        setSchoolIdentificationSuggestions(data);
        setShowSuggestions(true);
    };

    const onSuggestionClick = (suggestion) => {
        setValue("SchoolIdentificationNumber", suggestion.SchoolID);
        setShowSuggestions(false); // Blendet die Vorschläge aus
        setSchoolIdentificationSuggestions([]);
    };

    const onBlur = () => {
        setTimeout(() => setShowSuggestions(false), 100); // Verzögert das Ausblenden, um Klicks zu ermöglichen
    };

    return (
        <div className="form-container">
            <form onSubmit={handleSubmit(onSubmit)} className="contract-form">
                <div className="mb-3 form-field">
                    <label className="form-label">Schulkennzahl der Stammschule</label>
                    <input {...register("SchoolIdentificationNumber", {required: "Schulkennzahl ist erforderlich!"})}
                           type="number"
                           className="form-control"
                           onChange={(e) => {
                               setValue("SchoolIdentificationNumber", e.target.value);
                               fetchSchoolIdentificationSuggestions(e.target.value);
                           }}
                           onBlur={onBlur}
                           value={watch("SchoolIdentificationNumber")}
                    />
                    {schoolIdentificationSuggestions.length > 0 && (
                        <ul className="suggestions-list">
                            {schoolIdentificationSuggestions.map((suggestion, index) => (
                                <li
                                    key={index}
                                    onClick={() => onSuggestionClick(suggestion)}
                                    className="suggestion-item"
                                >
                                    {suggestion.SchoolID + " " + suggestion.SchoolName}
                                </li>
                            ))}
                        </ul>
                    )}
                    {errors.SchoolIdentificationNumber &&
                        <p className="error-message">{errors.SchoolIdentificationNumber.message}</p>}
                </div>
                <hr/>
                <div className="mb-3 form-group">
                    <label htmlFor="Start" className="form-label">Startdatum</label>
                    <input
                        type="date"
                        {...register("Start")}
                        className="form-control"
                    />
                    {errors.StartDate && <p className="error-message">{errors.StartDate.message}</p>}
                </div>

                <div className="mb-3 form-group">
                    <label htmlFor="End" className="form-label">Enddatum</label>
                    <input
                        type="date"
                        {...register("End")}
                        className="form-control"
                    />
                    {errors.EndDate && <p className="error-message">{errors.EndDate.message}</p>}
                </div>

                <div className="mb-3 form-group">
                    <label htmlFor="NewOrExtension" className="form-label">Neu/Verlängerung</label>
                    <select {...register("NewOrExtension", {required: "Neu/Verlängerung ist erforderlich!"})}
                            className="form-select">
                        <option value="NONE">Bitte wählen</option>
                        <option value="NEW">Neu</option>
                        <option value="EXTENSION">Verlängerung</option>
                    </select>
                    {errors.NewOrExtension && <p className="error-message">{errors.NewOrExtension.message}</p>}
                </div>
                <div className="mb-3 form-group">
                    <label htmlFor="ContractOrCivil" className="form-label">Beschäftigungsverhältnis</label>
                    <select {...register("ContractOrCivil", {required: "Feld ist erforderlich!"})}
                            className="form-select">
                        <option value="NONE">Bitte wählen</option>
                        <option value="CONTRACT">vertragsbedienstet</option>
                        <option value="CIVIL">pragmatisiert</option>
                    </select>
                    {errors.ContractOrCivil && <p className="error-message">{errors.ContractOrCivil.message}</p>}
                </div>
                {contractOrCivil === "CONTRACT" && (
                    <div className="mb-3 form-group">
                        <label htmlFor="FixedOrPermanent" className="form-label">Vertragstyp</label>
                        <select {...register("FixedOrPermanent", {required: "Vertragstyp ist erforderlich!"})}
                                className="form-select">
                            <option value="NONE">Bitte wählen</option>
                            <option value="PERMANENT">IL (unbefristet)</option>
                            <option value="FIXED">IIL (befristet)</option>
                        </select>
                    </div>
                )}
                {contractOrCivil === "CONTRACT" && (
                    <div className="mb-3 form-group">
                        <label htmlFor="ClassificationType" className="form-label">Einstufung</label>
                        <select {...register("ClassificationType", {required: "Einstufung/Abgeltungstyp ist erforderlich!"})}
                                className="form-select">
                            <option value="NONE">Bitte wählen</option>
                            {classificationTypes.map((type, index) => (
                                <option key={index} value={type.value}>{type.label}</option>
                            ))}
                        </select>
                    </div>
                )}
                {contractOrCivil === "CIVIL" && (
                    <div className="mb-3 form-group">
                        <label htmlFor="UsageType" className="form-label">Einstufung/Abgeltungstyp</label>
                        <select {...register("UsageType", {required: "Einstufung/Abgeltungstyp ist erforderlich!"})}
                                className="form-control">
                            <option value="">Bitte wählen</option>
                            {usageTypes.map((type, index) => (
                                <option key={index} value={type.value}>{type.label}</option>
                            ))}
                        </select>
                    </div>
                )}
                <div className="form-navigation-buttons">
                    <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={previousStep}
                    >
                        <FaArrowLeft/> Zurück
                    </button>
                    <button type="submit" className="btn btn-primary">{submitButtonText}</button>
                </div>
            </form>
        </div>
    );
};

export default ContractForm;
