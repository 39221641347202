import React, {createContext, useContext, useEffect, useState} from 'react';
import {decodeJWT} from "./jwtUtils";
import {persistor} from "./redux/store";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import {resetStore, setAuthToken, setEmail, setUserGroups} from "./redux/actions/actions";
import {PublicClientApplication} from "@azure/msal-browser";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const dispatch = useDispatch();
    const authToken = useSelector(state => state.user.authToken);
    const userGroups = useSelector(state => state.user.userGroups);
    const [msalInstance, setMsalInstance] = useState(null);

    useEffect(() => {
        const initializeMsal = async () => {
            const msalConfig = {
                auth: {
                    clientId: `${process.env.MS_CLIENT_ID}`,
                    authority: `https://login.microsoftonline.com/${process.env.MS_TENANT_ID}`, // Ersetzen Sie dies mit Ihrer Tenant-ID
                    redirectUri: `${process.env.REACT_DOMAIN}/auth` // Ersetzen Sie dies mit Ihrer Redirect-URI
                }
            };
            try {
                const msalInstance = await PublicClientApplication.createPublicClientApplication(msalConfig);
                setMsalInstance(msalInstance);
            } catch (error) {
                console.error("Fehler bei der Initialisierung von MSAL:", error);
            }
        };
        initializeMsal();
    }, []);


    useEffect(() => {
        const authToken = localStorage.getItem('authToken');
        if (authToken) {
            try {
                const decoded = decodeJWT(authToken);
                const currentTime = Date.now() / 1000;
                if (decoded.exp < currentTime) {
                    // Token ist abgelaufen
                    logout();
                    localStorage.removeItem('authToken');
                } else {
                    login(authToken);
                }
            } catch (error) {
                console.error('Token decoding failed:', error);
                logout();
            }
        }
    }, []);

    const handleLogin = async (credentials, onSuccess) => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/login-api/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(credentials),
        });
        if (response.headers.get('Content-Type')?.includes('application/json')) {
            const data = await response.json();
            if (response.ok && !data.error) {
                localStorage.setItem('authToken', data.token); // Speichern des Tokens im Local Storage

                dispatch(setAuthToken(data.token));
                const decoded = decodeJWT(data.token); // Decodieren des Tokens
                dispatch(setEmail(decoded.Email));
                dispatch(setUserGroups(decoded.Groups));

                if (onSuccess) {
                    onSuccess(decoded.Groups); // Übergeben der Rollen an den Callback
                }
            } else {
                toast("Username oder Passwort falsch", { type: "danger" });
            }
        } else {
            // Fehlerbehandlung, wenn die Antwort keinen JSON-Inhalt enthält
            console.error('Serverantwort ist kein JSON');
        }
    };

    const handleMicrosoftLogin = async (onSuccess) => {
        if (!msalInstance) {
            console.error("MSAL-Instanz ist noch nicht bereit.");
            return;
        }
        try {
            const authResult = await msalInstance.loginPopup({
                scopes: ["User.Read"]
            });

            if (authResult) {
                const mstoken = authResult.accessToken;
                authenticateUser(mstoken, `${process.env.REACT_APP_API_URL}/login-api/loginMicrosoft`, onSuccess);
            }
        } catch (error) {
            console.error("Fehler bei der Microsoft-Authentifizierung:", error);
        }
    };

    const authenticateUser = async (mstoken, url, onSuccess) => {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ mstoken })
        });
        if (response.headers.get('Content-Type')?.includes('application/json')) {
            const data = await response.json();
            if (response.ok && !data.error) {
                localStorage.setItem('authToken', data.token);
                dispatch(setAuthToken(data.token));
                const decoded = decodeJWT(data.token);
                dispatch(setEmail(decoded.Email));
                dispatch(setUserGroups(decoded.Groups));
                if (onSuccess) {
                    onSuccess(decoded.Groups);
                }
            } else {
                toast("Authentifizierungsfehler", { type: "danger" });
            }
        } else {
            console.error('Serverantwort ist kein JSON');
        }
    };

    const login = (token) => {
        localStorage.setItem('authToken', token);
        dispatch(setAuthToken(token));
        const decoded = decodeJWT(token);
        dispatch(setEmail(decoded.Email || ""));
        dispatch(setUserGroups(decoded.Groups || []));
    };

    const logout = () => {
        dispatch(resetStore());
        persistor.purge();
        localStorage.removeItem('authToken');
        localStorage.removeItem('persist:root');
        localStorage.clear();
    };


    return (
        <AuthContext.Provider value={{ authToken, userGroups, handleLogin, handleMicrosoftLogin, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
