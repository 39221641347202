import React, {useEffect} from 'react';
import './PersonalDataForm.css'
import '../error.css'
import {useDispatch, useSelector} from "react-redux";
import {addEmployeeFiles, getEmployee, removeEmployeeFile} from "../redux/actions/actions";
import {useForm} from "react-hook-form";
import {FaArrowLeft, FaTimes} from "react-icons/fa";

const AdditionalDataForm = ({contract, setCurrentStep, previousStep, submitButtonText }) => {
    const dispatch = useDispatch();
    const authToken = useSelector(state => state.user.authToken);
    const employee = useSelector(state => state.jointPower.employee); // Redux State für den aktuellen Mitarbeiter
    const { handleSubmit, reset } = useForm({
        defaultValues: employee // Setzt die Standardwerte des Formulars auf die aktuellen Mitarbeiterdaten
    });

    useEffect(() => {
        if (!employee.ID) {
            dispatch(getEmployee({contractID: contract.ID}));
        }
    }, [dispatch, employee, contract.ID]);

    useEffect(() => {
        reset(employee);
    }, [employee, reset]);

    const handleFileChange = (event) => {
        const files = event.target.files;
        const formData = new FormData();

        // Fügen Sie jede Datei zum FormData-Objekt hinzu
        for (let i = 0; i < files.length; i++) {
            formData.append('files[]', files[i]);
        }
        formData.append('employeeID', employee.ID);

        // Senden Sie die FormData an Ihr Backend
        fetch(`${process.env.REACT_APP_API_URL}/employee-api/uploadEmployeeFiles`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${authToken}`,
            },
            body: formData,
        })
        .then(response => {
            if (response.ok) {
                return response.json();
            }
            throw new Error('Netzwerkantwort war nicht ok.');
        })
        .then(data => {
            dispatch(addEmployeeFiles(employee.ID, data.newFileIDs));
        })
        .catch(error => {
            console.error('Fehler beim Hochladen der Datei:', error);
        });
    };

    const removeFile = (index, event) => {
        const fileToRemove = employee.EmployeeFiles[index];
        dispatch(removeEmployeeFile(employee.ID, fileToRemove.ID));
    };

    const onSubmit = data => {
        setCurrentStep();
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="form-container">
            <div>
                {(employee.EmployeeFiles || []).map((file, index) => (
                    <div key={index}>
                        {file.File.Name}
                        <span role="button" onClick={() => removeFile(index)} tabIndex="0" style={{ color: "red"}}><FaTimes/></span>
                    </div>
                ))}
            </div>
            <hr/>
            <div className="mb-3 form-field">
                <label className="form-label">Zusätzliche Dateien (optional): </label>
                <input type="file" onChange={handleFileChange} multiple/>
            </div>
            <div className="form-navigation-buttons">
                {previousStep && (
                    <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={previousStep}
                    >
                        <FaArrowLeft/> Zurück
                    </button>
                )}
                <button type="submit" className="btn btn-primary">{submitButtonText}</button>
            </div>
        </form>
    );
};

export default AdditionalDataForm;
