import React, { useEffect, useState } from 'react';
import {decodeJWT} from "../jwtUtils";
import './FederalStateContractsTable.css';
import {useDispatch, useSelector} from "react-redux";
import {confirmContract, getContracts} from "../redux/actions/actions";
import Navigation from "../ui/Navigation";
import {FaCheck, FaTimes} from "react-icons/fa";

const FederalStateContractsTable = () => {
    const dispatch = useDispatch();
    const authToken = useSelector(state => state.user.authToken);
    const contracts = useSelector(state => state.jointPower.contracts);

    useEffect(() => {
        if (!contracts.length) {
            dispatch(getContracts());
        }
    }, [dispatch, contracts]);

    const downloadFile = async (token, contractID, fileType) => {
        let apiUrl;
        let fileName;

        switch (fileType) {
            case 'PDF':
                apiUrl = `${process.env.REACT_APP_API_URL}/jointpower-api/getPDF/${contractID}`;
                fileName = "contract.pdf";
                break;
            case 'BBA':
                apiUrl = `${process.env.REACT_APP_API_URL}/jointpower-api/getBBA/${contractID}`;
                fileName = "bba.pdf";
                break;
            case 'DBD':
                apiUrl = `${process.env.REACT_APP_API_URL}/jointpower-api/getDBD/${contractID}`;
                fileName = "dbd.pdf";
                break;
            default:
                console.error("Unbekannter Dateityp");
                return;
        }

        const response = await fetch(apiUrl, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            method: 'GET'
        });

        if (!response.ok) {
            console.error(`Fehler beim Herunterladen der Datei: ${fileType}`);
            return;
        }

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
    };

    return (
        <div className="container contract-details">
            <h2 className="text-center my-4">Vertragsübersicht</h2>
            <div className="row header-row d-none d-md-flex">
                <div className="col"><strong>Personalnummer</strong></div>
                <div className="col"><strong>Nachname</strong></div>
                <div className="col"><strong>Vorname</strong></div>
                <div className="col"></div>
            </div>
            {contracts.map((contractEntry, index) => (
                <div key={index} className="row data-row">
                    <div className="col-12 col-md-3">{contractEntry.employee && contractEntry.employee.SchoolEmployeeNumber}</div>
                    <div className="col-12 col-md-3">{contractEntry.employee && contractEntry.employee.FamilyName}</div>
                    <div className="col-12 col-md-3">{contractEntry.employee && contractEntry.employee.GivenName}</div>
                    <div className="col-12 col-md-3">
                        <div>
                            <span className="links">
                                <a
                                    onClick={() => downloadFile(authToken, contractEntry.contract.ID, 'PDF')}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="download-link"
                                >
                                    PDF
                                </a>
                            </span>
                            <span className="links">
                                <a
                                    onClick={() => downloadFile(authToken, contractEntry.contract.ID, 'BBA')}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="download-link"
                                >
                                    Beiblatt
                                </a>
                            </span>
                            <span className="links">
                                <a
                                    onClick={() => downloadFile(authToken, contractEntry.contract.ID, 'DBD')}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="download-link"
                                >
                                    Besoldung
                                </a>
                            </span>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default FederalStateContractsTable;
