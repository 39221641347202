import React, {useEffect, useState} from 'react';
import {FaTrash, FaPlus, FaArrowLeft} from 'react-icons/fa';
import '../error.css'
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {
    addEmployment, getEmployee, getEmployments,
    getInstitutes,
    removeEmployment,
    updateEmployments
} from "../redux/actions/actions";
import {decodeJWT} from "../jwtUtils";

const EmploymentForm = ({ contract, setCurrentStep, previousStep, submitButtonText }) => {
    const dispatch = useDispatch();
    const institutes = useSelector(state => state.jointPower.institutes);
    const employments = useSelector(state => state.jointPower.employments);
    const { register, handleSubmit, reset, formState: { errors } } = useForm({
        defaultValues: employments // Setzt die Standardwerte des Formulars auf die aktuellen Mitarbeiterdaten
    });
    const employee = useSelector(state => state.jointPower.employee); // Redux State für den aktuellen Mitarbeiter
    //const email = decodeJWT(localStorage.getItem('authToken')).Email;
    const [employmentsError, setEmploymentsError] = useState('');

    useEffect(() => {
        if (!institutes.length) {
            dispatch(getInstitutes());
        }
        if (!('ID' in employee)) {
            dispatch(getEmployee({contractID: contract.ID}));
        }
        if (employee && !employments.length) {
            dispatch(getEmployments(employee.ID));
        }
        if (employments.length > 0 && employments[0].ContractID !== contract.ID) {
            dispatch(getEmployments(employee.ID));
        }
    }, [dispatch, institutes, employments, employee, contract]);

    useEffect(() => {
        reset(institutes);
    }, [institutes, reset]);

    useEffect(() => {
        reset(employments);
    } , [employments, reset]);


    const onSubmit = data => {
        const updatedEmployments = employments.map((employment, index) => ({
            ...employment, // Behält alle bestehenden Eigenschaften von employment bei
            JobTitle: data.employments[index].JobTitle,
            NumOfUnits: data.employments[index].NumOfUnits,
            OrgUnitID: data.employments[index].OrgUnitID,
            UnitType: data.employments[index].UnitType,
            NumOfUnitsTeaching: data.employments[index].NumOfUnitsTeaching,
            UnitTypeTeaching: data.employments[index].UnitTypeTeaching,
            IsTeaching: data.employments[index].IsTeaching
        }));
        const validation = validateEmployments(updatedEmployments);
        if (validation === true) {
            dispatch(updateEmployments(updatedEmployments)); // Dispatch der Redux-Aktion mit den Formulardaten
            setCurrentStep();
        } else {
            setEmploymentsError(validation);
        }
    };

    const createEmployment = (employeeID) => {
        if (institutes.length) {
            dispatch(addEmployment(employeeID))
        } else {
            setEmploymentsError('Es konnten keine Institute geladen werden. Bitte versuchen Sie es später erneut.');
        }
    };

    const dropEmployment = (index) => {
        dispatch(removeEmployment(index))
    };

    const validateEmployments = (employments) => {
        if (employments.length === 1 && !employments[0].NumOfUnits) return 'Mindestens eine Beschäftigung ist erforderlich';
        if (employments.some(emp => !emp.OrgUnitID || !emp.JobTitle || !emp.NumOfUnits)) {
            return 'Alle Felder in den Beschäftigungen müssen ausgefüllt sein';
        }
        return true;
    };

    if (!institutes.length) return (<div>Es konnten keine Institute geladen werden. Bitte versuchen Sie es später erneut.</div>);

    return (
        <div className="form-container">
            <form onSubmit={handleSubmit(onSubmit)} className="contract-form">
                <div className="mb-3 form-field">
                    <label className="form-label">Beschäftigungen</label>
                    {employments.map((employment, index) => (
                        <div key={index} className="row mb-3" style={{paddingTop: "3.5vh"}}>
                            <div className="col-12">
                                <select {...register(`employments[${index}].OrgUnitID`)}
                                        defaultValue={employment.OrgUnitID} className="form-select">
                                    <option value="" disabled>Wählen Sie ein Institut</option>
                                    {institutes.map((institute, i) => (
                                        <option key={i} value={institute.ID}>{institute.Name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-12" style={{paddingTop: "2vh"}}>
                                <input {...register(`employments[${index}].JobTitle`)}
                                       defaultValue={employment.JobTitle} type="text" className="form-control"
                                       placeholder="Bezeichnung der Stelle"/>
                            </div>
                            <div className="col-12" style={{ paddingTop: "2vh"}}>Lehrend</div>
                            <div className="col-3">
                                <input {...register(`employments[${index}].NumOfUnitsTeaching`)}
                                       defaultValue={employment.NumOfUnitsTeaching} type="number" min="0" step="0.001"
                                       className="form-control"
                                       placeholder="Anzahl der Einheiten"/>
                            </div>
                            <div className="col-3">
                                <select  {...register(`employments[${index}].UnitTypeTeaching`)}
                                         defaultValue={employment.UnitTypeTeaching} className="form-select">
                                    <option value="" disabled>Wählen Sie</option>
                                    <option value="WE">WE</option>
                                    <option value="STD">Std</option>
                                </select>
                            </div>
                            <div className="col-12"  style={{ paddingTop: "2vh"}}>Nicht Lehrend</div>
                            <div className="col-3">
                                <input {...register(`employments[${index}].NumOfUnits`)}
                                       defaultValue={employment.NumOfUnits} type="number" min="0" step="0.001"
                                       className="form-control"
                                       placeholder="Anzahl der Einheiten"/>
                            </div>
                            <div className="col-3">
                                <select  {...register(`employments[${index}].UnitType`)}
                                         defaultValue={employment.UnitType} className="form-select">
                                    <option value="" disabled>Wählen Sie</option>
                                    <option value="WE">WE</option>
                                    <option value="STD">Std</option>
                                </select>
                            </div>
                            <div className="col-12" style={{paddingTop: "2vh", textAlign: "right"}}>
                                <FaTrash
                                    className="remove-icon"
                                    onClick={() => dropEmployment(employment.ID)}
                                    style={{cursor: 'pointer', color: 'red'}}
                                />
                            </div>
                        </div>
                    ))}
                    <hr/>
                </div>
                {employmentsError && <p className="error-message">{employmentsError}</p>}
                <div className="form-navigation-buttons">
                    {previousStep && (
                        <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={previousStep}
                        >
                            <FaArrowLeft/> Zurück
                        </button>
                    )}
                    <button type="submit" className="btn btn-primary">{submitButtonText}</button>
                </div>
            </form>
        </div>
    );
};

export default EmploymentForm;