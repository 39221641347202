import React, { useState, useEffect } from 'react';
import {FaCheck, FaDownload, FaEdit, FaInfo, FaTimes, FaTrash} from 'react-icons/fa';
import './Overview.css';
import {useDispatch, useSelector} from "react-redux";
import {
    confirmContract,
    confirmEmployment,
    deleteContract,
    getContracts, getSchoolYears,
    updateEmploymentUnits
} from "./redux/actions/actions";
import FormSummary from "./FormSummary";
import ConfirmDeleteModal from "./ConfirmDeleteModal";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

function Overview() {
    const [editEmployment, setEditEmployment] = useState(null);
    const [editValue, setEditValue] = useState('');
    const [editTeachingValue, setEditTeachingValue] = useState('');

    const dispatch = useDispatch();
    const contracts = useSelector(state => state.jointPower.contracts);
    const userGroups = useSelector(state => state.user.userGroups);
    const authToken = useSelector(state => state.user.authToken);
    const schoolYears = useSelector(state => state.jointPower.schoolYears);
    const isEditableGroup = userGroups.includes('institute') || userGroups.includes('rectorate')
    const isRectorate = userGroups.includes('rectorate');
    const isInstitute = userGroups.includes('institute') || userGroups.includes('assistant');
    const isHR = userGroups.includes('hr');
    const [searchTerm, setSearchTerm] = useState('');
    const [contractToShow, setContractToShow] = useState(null);
    const [selectedSchoolYear, setSelectedSchoolYear] = useState('');
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [contractToDelete, setContractToDelete] = useState(null);


    useEffect(() => {
        if(!contracts.length) {
            dispatch(getContracts());
        }
    }, [dispatch, contracts?.length]);

    useEffect(() => {
        if (!schoolYears.length) {
            dispatch(getSchoolYears());
        }
    }, [dispatch, schoolYears?.length]);

    useEffect(() => {
        if (selectedSchoolYear) {
            dispatch(getContracts(selectedSchoolYear));
        }
    }, [selectedSchoolYear, dispatch]);

    const handleEditChange = (e) => {
        setEditValue(e.target.value);
    };

    const handleEditTeachingChange = (e) => {
        setEditTeachingValue(e.target.value);
    };

    const saveEdit = () => {
        if (editEmployment) {
            // Senden der Anfrage an den Server
            contracts.filter(contract => contract.contract.ID === editEmployment.contractId).forEach(contract => {
                contract.employments.filter(employment => employment.employment.ID === editEmployment.employmentId).forEach(employment => {
                    dispatch(updateEmploymentUnits(editEmployment.employmentId, editValue, editTeachingValue));
                });
            });
        }
        setEditEmployment(null);
    };

    const handleEmploymentConfirmation = (employmentID, contractID, status) => {
        if (isEditableGroup) {
            dispatch(confirmEmployment(employmentID, contractID, status))
        }
    };

    const handleContractConrimation = (contractID, status) => {
        if (isRectorate) {
            dispatch(confirmContract(contractID, status));
        }
    }

    const downloadFile = async (token, contractID, fileType) => {
        let apiUrl;
        let fileName;

        switch (fileType) {
            case 'PDF':
                apiUrl = `${process.env.REACT_APP_API_URL}/jointpower-api/getPDF/${contractID}`;
                fileName = "contract.pdf";
                break;
            case 'BBA':
                apiUrl = `${process.env.REACT_APP_API_URL}/jointpower-api/getBBA/${contractID}`;
                fileName = "bba.pdf";
                break;
            case 'DBD':
                apiUrl = `${process.env.REACT_APP_API_URL}/jointpower-api/getDBD/${contractID}`;
                fileName = "dbd.pdf";
                break;
            default:
                console.error("Unbekannter Dateityp");
                return;
        }

        const headers = new Headers({
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
            'Content-Type': 'application/json'
        });

        const config = {
            method: 'GET',
            headers: headers,
        };

        const response = await fetch(apiUrl, config);

        if (!response.ok) {
            console.error(`Fehler beim Herunterladen der Datei: ${fileType}`);
            return;
        }

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
    };

    const downloadAll = async (token) => {
        let apiUrl;
        let fileName;

        apiUrl = `${process.env.REACT_APP_API_URL}/jointpower-api/getAllPDFs`;
        fileName = "all.zip";

        const headers = new Headers({
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
            'Content-Type': 'application/json'
        });

        const config = {
            method: 'GET',
            headers: headers,
        };

        const response = await fetch(apiUrl, config);

        if (!response.ok) {
            return;
        }

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
    };

    const resetContractToShow = () => {
        setContractToShow(null);
    }

    const setContractToShowHandler = (contract) => {
        setContractToShow({ ...contract });
    };

    const requestDeleteContract = (contract) => {
        setContractToDelete({...contract });
        setShowConfirmModal(true);
    };

    const confirmDeleteContract = () => {
        if (contractToDelete) {
            dispatch(deleteContract(contractToDelete));
            setContractToDelete(null);
            setShowConfirmModal(false);
        }
    };

    return (
        <>
            <ConfirmDeleteModal
                show={showConfirmModal}
                onHide={() => setShowConfirmModal(false)}
                onConfirm={confirmDeleteContract}
            />
            {contractToShow && (
                <div className="container contract-details">
                    <div className="row header-row d-none d-md-flex">
                        <div className="col">
                            <FormSummary contract={contractToShow} setCurrentStep="" previousStep={resetContractToShow} submitButtonText="" />
                        </div>
                    </div>
                </div>
            )}
            {!contractToShow && (
            <div className="container employee-details">
                <h2 className="text-center my-4">Übersicht über Mitverwendungen</h2>
                <div className="row mb-3">
                    <div className="col-4">
                        <div className="search-input">
                            <input
                                type="text"
                                placeholder="Suche..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                className="form-control"
                            />
                        </div>
                    </div>
                    <div className="col-4">
                        &nbsp;
                    </div>
                    <div className="col-4">
                        <select
                            className="form-select"
                            value={selectedSchoolYear}
                            onChange={(e) => setSelectedSchoolYear(e.target.value)}
                        >
                            <option value="">Schuljahr</option>
                            {schoolYears.map((year) => (
                                <option key={year.ID} value={year.ID}>{year.Name}</option>
                            ))}
                        </select>
                    </div>

                </div>
                <div className="row header-row d-none d-md-flex">
                    <div className="col-md-1"><strong>Status</strong></div>
                    <div className="col-md-1"><strong>Email</strong></div>
                    <div className="col-md-1"><strong>Nachname</strong></div>
                    <div className="col-md-1"><strong>Vorname</strong></div>
                    <div className="col-md-5"><strong>Institut</strong></div>
                    <div className="col-md-2 text-end">
                        <OverlayTrigger
                            key="all-download"
                            placement="top"
                            overlay={<Tooltip id={`tooltip-alldownload`}>Alle vom Rektorat genehmigten Mitverwendungen herunterladen</Tooltip>}
                        >
                            <a target="_blank" rel="noopener noreferrer" className="download-link"
                               onClick={() => downloadAll(authToken)}>
                                <strong><FaDownload/></strong>
                            </a>
                        </OverlayTrigger>
                    </div>
                </div>
                {contracts
                    .filter((contractEntry) => {
                        const term = searchTerm.toLowerCase();
                        return (
                            contractEntry.employee.InitialEmail.toLowerCase().includes(term) || contractEntry.employee.FamilyName?.toLowerCase().includes(term) || contractEntry.employee.GivenName?.toLowerCase().includes(term)
                        );
                    })
                    .map((contractEntry, index) => (
                    <div key={index} className="row data-row">
                        <div className="col-12 col-md-1" style={{ fontSize: "0.75rem"}}>
                            {contractEntry.contract.Status}
                        </div>
                        <div className="col-12 col-md-1" style={{ fontSize: "0.75rem", wordWrap: "break-word"}}>{contractEntry.employee.InitialEmail}</div>
                        <div className="col-12 col-md-1" style={{ fontSize: "0.75rem", wordWrap: "break-word"}}>{contractEntry.employee.FamilyName}</div>
                        <div className="col-12 col-md-1" style={{ fontSize: "0.75rem", wordWrap: "break-word"}}>{contractEntry.employee.GivenName}</div>
                        <div className="col-12 col-md-5">
                            {contractEntry.employments.map((employmentEntry, empIndex) => (
                                <div key={empIndex}>
                                    {editEmployment && editEmployment.employmentId === employmentEntry.employment.ID && isEditableGroup ? (
                                        <>
                                            {employmentEntry.employment.NumOfUnitsTeaching > 0 &&
                                                <input
                                                    type="number"
                                                    value={editTeachingValue}
                                                    onChange={handleEditTeachingChange}
                                                    onBlur={saveEdit}
                                                    step="0.1"
                                                />
                                            }
                                            {employmentEntry.employment.NumOfUnits > 0 &&
                                            <input
                                                type="number"
                                                value={editValue}
                                                onChange={handleEditChange}
                                                onBlur={saveEdit}
                                                step="0.1"
                                            />
                                            }
                                        </>
                                    ) : (
                                        <>
                                            <span className="small-font">
                                                {employmentEntry.orgUnit.Name} - {employmentEntry.employment.JobTitle}
                                            </span>
                                            {employmentEntry.employment.NumOfUnitsTeaching > 0 &&
                                            <span className="small-font"
                                                  onClick={() => contractEntry.contract.Status !== "RECTORATE" && isEditableGroup}>
                                                   &nbsp; - Lehre: { employmentEntry.employment.NumOfUnitsTeaching + employmentEntry.employment.UnitTypeTeaching}
                                            </span>
                                            }
                                            {employmentEntry.employment.NumOfUnits > 0 &&
                                            <span className="small-font"
                                                  onClick={() => contractEntry.contract.Status !== "RECTORATE" && isEditableGroup}>
                                                 &nbsp; - Planend: { employmentEntry.employment.NumOfUnits + employmentEntry.employment.UnitType }
                                            </span>
                                            }
                                            {isEditableGroup && employmentEntry.employment.Status === 'INITIATED' && (
                                                <>
                                                    <OverlayTrigger
                                                        key={`deny-emp-${contractEntry.contract.ID}-${employmentEntry.employment.ID}`}
                                                        placement="top"
                                                        overlay={<Tooltip
                                                            id={`tooltip-deny-emp-${contractEntry.contract.ID}`}>Mitverwendung
                                                            ablehnen</Tooltip>}
                                                    >
                                                        <span
                                                            className="confirm-icon"
                                                            onClick={() => handleEmploymentConfirmation(employmentEntry.employment.ID, contractEntry.contract.ID, "INITIATED")}
                                                            style={{cursor: 'pointer', color: 'red'}}
                                                        >
                                                            <FaTimes/>
                                                        </span>
                                                    </OverlayTrigger>
                                                    <OverlayTrigger
                                                        key={`confirm-emp-${contractEntry.contract.ID}-${employmentEntry.employment.ID}`}
                                                        placement="top"
                                                        overlay={<Tooltip
                                                            id={`tooltip-confirm-emp-${contractEntry.contract.ID}`}>Mitverwendung
                                                            bestätigen</Tooltip>}
                                                    >
                                                        <span
                                                            className="confirm-icon"
                                                            onClick={() => handleEmploymentConfirmation(employmentEntry.employment.ID, contractEntry.contract.ID, "INSTITUTE")}
                                                            style={{
                                                                cursor: 'pointer',
                                                                color: 'green',
                                                                marginLeft: '10px'
                                                            }}
                                                        >
                                                            <FaCheck/>
                                                        </span>
                                                    </OverlayTrigger>
                                                </>
                                            )}
                                        </>
                                    )}
                                </div>
                            ))}
                        </div>
                        <div className="col-12 col-md-2">
                            {isRectorate && contractEntry.contract.Status === 'INSTITUTE' && (
                                <>
                                    <OverlayTrigger
                                        key={`deny-${contractEntry.contract.ID}`}
                                        placement="top"
                                        overlay={<Tooltip id={`tooltip-deny-${contractEntry.contract.ID}`}>Vertrag
                                            ablehnen</Tooltip>}
                                    >
                                        <span
                                            className="confirm-icon"
                                            data-tip="Vertrag ablehnen"
                                            onClick={() => handleContractConrimation(contractEntry.contract.ID, "NONE")}
                                            style={{cursor: 'pointer', color: 'red'}}
                                        ><FaTimes/>
                                        </span>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                        key={`confirm-${contractEntry.contract.ID}`}
                                        placement="top"
                                        overlay={<Tooltip id={`tooltip-confirm-${contractEntry.contract.ID}`}>Vertrag bestätigen</Tooltip>}
                                    >
                                        <span
                                            className="confirm-icon"
                                            data-tip="Vertrag bestätigen"
                                            onClick={() => handleContractConrimation(contractEntry.contract.ID, "RECTORATE")}
                                            style={{cursor: 'pointer', color: 'green'}}
                                        >
                                            <FaCheck/>
                                        </span>
                                    </OverlayTrigger>
                                </>
                            )}
                            {contractEntry.contract.Status === 'RECTORATE' && (
                                <>
                                    <span className="links">
                                        <OverlayTrigger
                                            key={`pdf-${contractEntry.contract.ID}`}
                                            placement="top"
                                            overlay={<Tooltip id={`tooltip-pdf-${contractEntry.contract.ID}`}>Mitverwendung herunterladen</Tooltip>}
                                        >
                                            <a
                                                onClick={() => downloadFile(authToken, contractEntry.contract.ID, 'PDF')}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="download-link"
                                            >
                                                PDF
                                            </a>
                                        </OverlayTrigger>
                                    </span>
                                    <span className="links">
                                        <OverlayTrigger
                                            key={`addition-${contractEntry.contract.ID}`}
                                            placement="top"
                                            overlay={<Tooltip id={`tooltip-addition-${contractEntry.contract.ID}`}>Beiblatt herunterladen</Tooltip>}
                                        >
                                            <a
                                                onClick={() => downloadFile(authToken, contractEntry.contract.ID, 'BBA')}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="download-link"
                                            >
                                                Beiblatt
                                            </a>
                                        </OverlayTrigger>
                                    </span>
                                    {(contractEntry.contract.NewOrExtension === 'NEW' || isHR || isRectorate) && (
                                    <span className="links">
                                        <OverlayTrigger
                                            key={`money-${contractEntry.contract.ID}`}
                                            placement="top"
                                            overlay={<Tooltip id={`tooltip-money-${contractEntry.contract.ID}`}>Besoldung herunterladen</Tooltip>}
                                        >
                                            <a
                                                onClick={() => downloadFile(authToken, contractEntry.contract.ID, 'DBD')}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="download-link"
                                            >
                                                Besoldung
                                            </a>
                                        </OverlayTrigger>
                                    </span>
                                    )}
                                </>
                            )}
                            {isInstitute && (contractEntry.contract.Status === 'INITIATED') && (
                                <span className="links">
                                    <OverlayTrigger
                                        key={`data-${contractEntry.contract.ID}`}
                                        placement="top"
                                        overlay={<Tooltip id={`tooltip-data-${contractEntry.contract.ID}`}>Mitverwendungsdaten erfassen</Tooltip>}
                                    >
                                        <a href={`/mdb/institute/employment/details/${contractEntry.contract.ID}`}>
                                            <FaEdit></FaEdit>
                                        </a>
                                    </OverlayTrigger>
                                </span>
                            )}
                            {isInstitute && contractEntry.contract.Status === 'NONE' && (
                                <span className="links">
                                   <OverlayTrigger
                                       key={`trash-${contractEntry.contract.ID}`}
                                       placement="top"
                                       overlay={<Tooltip id={`tooltip-trash-${contractEntry.contract.ID}`}>Mitverwendung löschen</Tooltip>}
                                   >
                                        <a onClick={() => requestDeleteContract(contractEntry.contract)} style={{color: "blue"}}>
                                            <FaTrash/>
                                        </a>
                                   </OverlayTrigger>
                                </span>
                            )}
                            <span className="links">
                               <OverlayTrigger
                                   key={`info-${contractEntry.contract.ID}`}
                                   placement="top"
                                   overlay={<Tooltip id={`tooltip-info-${contractEntry.contract.ID}`}>Details zur Mitverwendung anzeigen</Tooltip>}
                               >
                                   <a onClick={() => setContractToShowHandler(contractEntry.contract)} style={{ color: "blue" }}><FaInfo/></a>
                               </OverlayTrigger>
                            </span>
                        </div>
                    </div>
                    ))}
                <div className="row data-row">
                    <div className="col-12 text-end">
                        Total: {contracts.length && contracts.reduce((acc, contractEntry) => {
                            return acc + contractEntry.contract.TotalUnits
                        }, 0)}
                    </div>
                </div>
            </div>
            )}
        </>
    );
}

export default Overview;